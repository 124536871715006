import React, { useState, useRef } from "react";
import axios from "axios";
import config from "../../../component/config";
import { Toast } from "primereact/toast";
import { Link, useNavigate } from "react-router-dom";
const SelectChannel = () => {
  const [channels, setChannels] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const [errors, setErrors] = useState({
    api_id: "",
    mobile_number: "",
    api_hash: "",
    telegram_bot_token: "",
    login_code: "",
  });

  const [formData, setFormData] = useState({
    api_id: "",
    api_hash: "",
    mobile_number: "",
    telegram_bot_token: "",
    login_code: "",
  });
  const validateFields = () => {
    let newErrors = { ...errors };
    let isValid = true;

    Object.keys(formData).forEach((field) => {
      const value = formData[field];

      switch (field) {
        case "api_id":
          newErrors.api_id = value ? "" : "API ID is required";
          break;
        case "mobile_number":
          newErrors.mobile_number = value ? "" : "Mobile Number is required";
          break;
        case "api_hash":
          newErrors.api_hash = value ? "" : "API Hash is required";
          break;
        case "telegram_bot_token":
          newErrors.telegram_bot_token = value
            ? ""
            : "Telegram Bot Token is required";
          break;

        default:
          break;
      }

      if (newErrors[field]) {
        console.log(`Validation failed for ${field}: ${newErrors[field]}`); // Debug log
        isValid = false;
      }
    });

    setErrors(newErrors);

    return isValid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };

      switch (name) {
        case "api_id":
          updatedErrors.api_id = value ? "" : "API ID is required";
          break;
        case "mobile_number":
          updatedErrors.mobile_number = value
            ? ""
            : "Mobile Number is required";
          break;
        case "api_hash":
          updatedErrors.api_hash = value ? "" : "API Hash is required";
          break;
        case "telegram_bot_token":
          updatedErrors.telegram_bot_token = value
            ? ""
            : "Telegram Bot Token is required";
          break;

        default:
          break;
      }

      return updatedErrors;
    });
  };

  const handleSendCode = async () => {
    if (!validateFields()) {
      return;
    }

    const authToken = localStorage.getItem("authToken");
    setLoading(true);

    try {
      console.log("Sending API request..."); // Debug log
      const response = await axios.post(
        `${config.apiDomain}/api/teacher/telegram/send_code`,
        {
          teacher_id: userId,
          api_id: formData.api_id,
          api_hash: formData.api_hash,
          mobile_number: formData.mobile_number,
          telegram_bot_token: formData.telegram_bot_token,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("API response received:", response); // Debug log

      if (response.data.st === 1) {
        if (response.data.st === 1) {
          window.showToast("success", response.data.msg || "Code sent successfully");
          setCurrentStep(2); // Move to the next step
        } else {
          window.showToast("error", response.data.msg || "Failed to send code");
        }
      } }catch (error) {
        console.error("Error occurred during API request:", error); // Debug log
        if (error.response && error.response.status === 401) {
          navigate("/login");
        } else {
          const errorMsg = error.response?.data?.msg || "Failed to send code";
          window.showToast("error", errorMsg);
        }
      } finally {
        setLoading(false);
      }
    };              

  const handleVerifyCode = async () => {
    // Validate all fields
    if (!validateFields()) return; // Check validation

    const authToken = localStorage.getItem("authToken");

    try {
      const response = await axios.post(
        `${config.apiDomain}/api/teacher/telegram/get_channel_list`,
        {
          teacher_id: userId,
          api_id: formData.api_id,
          api_hash: formData.api_hash,
          mobile_number: formData.mobile_number,
          telegram_bot_token: formData.telegram_bot_token,
          login_code: formData.login_code,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.st === 1) {
        setChannels(response.data.telegram_channels);
        setCurrentStep(3);
      } else {
        
        window.showToast("error", response.data.msg || "Failed to verify code");

      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error(
          "Unauthorized access, redirecting to common login screen"
        );
        navigate("/login");
      } else {
        const errorMsg = error.response?.data?.msg || "Failed to verify code";
        
        window.showToast("error", errorMsg || "Failed to verify code");

      }
    }
  };

  const handleSelectChannel = async () => {
    const authToken = localStorage.getItem("authToken");

    try {
      const selectedChannels = channels
        .filter((channel) => channel.selected)
        .map((channel) => channel.channel_username);

      const response = await axios.post(
        `${config.apiDomain}/api/teacher/telegram/select_channel`,
        {
          teacher_id: userId,
          channel_username: selectedChannels,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.st === 1) {
        
        window.showToast("success", response.data.msg || "Channels selected successfully");

      } else {
        
        window.showToast("error", response.data.msg || "Failed to select channels");

      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error(
          "Unauthorized access, redirecting to common login screen"
        );
        navigate("/login");
      } else {
        const errorMsg =
          error.response?.data?.msg || "Failed to select channels";
      
        window.showToast("error", errorMsg || "Failed to select channels");

      }
    }
  };

  const toggleChannelSelection = (index) => {
    const updatedChannels = [...channels];
    updatedChannels[index].selected = !updatedChannels[index].selected;
    setChannels(updatedChannels);
  };
  const resetForm = () => {
    setFormData({
      api_id: "",
      mobile_number: "",
      api_hash: "",
      telegram_bot_token: "",
      login_code: "",
    });
    setErrors({});
    setCurrentStep(1);
    setChannels([]);
  };

  // Add modal state
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Add modal controls
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    resetForm();
  };

  return (
    <div>
      <Toast ref={toast} />
      
      <button
        className="btn btn-outline-primary btn-sm"
        onClick={openModal}
      >
        <i className="ri-user-settings-line text-primary ri-sm"></i>
      </button>

      <div
        className={`modal ${isModalOpen ? 'show' : ''}`}
        id="wizardModal"
        tabIndex="-1"
        aria-hidden={!isModalOpen}
        style={{ 
          display: isModalOpen ? 'block' : 'none',
          zIndex: 1055  // Ensure modal is above backdrop
        }}
      >
        {/* Backdrop with lower z-index */}
        {isModalOpen && (
          <div 
            className="modal-backdrop fade show" 
            style={{ zIndex: 1050 }}  // Ensure backdrop is below modal
          />
        )}
        
        <div className="modal-dialog modal-lg modal-simple modal-edit-user" style={{ position: 'relative', zIndex: 1056 }}>
          <div className="modal-content">
            <div className="modal-body p-0">
              <button
                type="button"
                className="btn-close"
                onClick={closeModal}
                aria-label="Close"
              ></button>

              {currentStep === 1 && (
                <div>
                  <div className="row justify-content-center">
                    <div className="col-12 mb-0 text-center">
                      <div className="bs-stepper wizard-modern wizard-modern-example mt-2 text-center">
                        <div className="bs-stepper-header">
                          <div
                            className="step"
                            data-target="#account-details-modern"
                          >
                            <button
                              type="button"
                              className="step-trigger d-flex flex-column align-items-center"
                            >
                              <span className="bs-stepper-circle  bg-primary custom-stepper-circle"></span>
                              <span className="bs-stepper-label mt-2 text-center">
                                <span className="d-flex flex-column align-items-center">
                                  <span className="bs-stepper-title">
                                    User Details
                                  </span>
                                </span>
                              </span>
                            </button>
                          </div>

                          <div
                            className="progress mb-1 text-center rounded mt-0 mx-auto "
                            style={{ height: "9px", width: "58%" }}
                          >
                            <div
                              className="progress-bar rounded"
                              role="progressbar"
                              aria-valuenow="65"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <div
                            className="step"
                            data-target="#personal-info-modern"
                          >
                            <button
                              type="button"
                              className="step-trigger d-flex flex-column align-items-center"
                            >
                              <span className="bs-stepper-circle bg-white custom-stepper-circle d-flex justify-content-center align-items-center"></span>
                              <span className="bs-stepper-label mt-2 text-center">
                                <span className="d-flex flex-column gap-1">
                                  <span className="bs-stepper-title">
                                    Select Channel
                                  </span>
                                </span>
                              </span>
                            </button>
                          </div>
                        </div>
                        <p className="text-center mb-5  pb-3 mx-auto w-100">
                          Check out below plan for more details check out below
                          plan for more details Check out below plan for more
                          details check out below plan for more details
                        </p>
                      </div>
                    </div>
                  </div>
                  <form
                    id="sendCodeForm"
                    className="row g-5"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSendCode(); // Trigger API call on form submit
                    }}
                  >
                    <div className="col-12 col-md-6">
                      <div className="form-floating form-floating-outline">
                        <input
                          type="text"
                          id="api_id"
                          name="api_id"
                          className={`form-control ${
                            errors.api_id ? "is-invalid" : ""
                          }`}
                          value={formData.api_id}
                          onChange={handleInputChange}
                          placeholder="Enter API ID"
                        />
                        <label htmlFor="api_id">API ID</label>
                        {errors.api_id && (
                          <div className="invalid-feedback">
                            {errors.api_id}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-floating form-floating-outline">
                        <input
                          type="text"
                          id="mobile_number"
                          name="mobile_number"
                          className={`form-control ${
                            errors.mobile_number ? "is-invalid" : ""
                          }`}
                          value={formData.mobile_number}
                          onChange={handleInputChange}
                          placeholder="Enter Mobile Number"
                        />
                        <label htmlFor="mobile_number">Mobile Number</label>
                        {errors.mobile_number && (
                          <div className="invalid-feedback">
                            {errors.mobile_number}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-floating form-floating-outline">
                        <input
                          type="text"
                          id="api_hash"
                          name="api_hash"
                          className={`form-control ${
                            errors.api_hash ? "is-invalid" : ""
                          }`}
                          value={formData.api_hash}
                          onChange={handleInputChange}
                          placeholder="Enter Hash Code"
                        />
                        <label htmlFor="api_hash">Hash Code</label>
                        {errors.api_hash && (
                          <div className="invalid-feedback">
                            {errors.api_hash}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-floating form-floating-outline">
                        <input
                          type="text"
                          id="telegram_bot_token"
                          name="telegram_bot_token"
                          className={`form-control ${
                            errors.telegram_bot_token ? "is-invalid" : ""
                          }`}
                          value={formData.telegram_bot_token}
                          onChange={handleInputChange}
                          placeholder="Enter Bot Token"
                        />
                        <label htmlFor="telegram_bot_token">Bot Token</label>
                        {errors.telegram_bot_token && (
                          <div className="invalid-feedback">
                            {errors.telegram_bot_token}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 text-end">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading}
                      >
                        {loading ? (
                          <div className="spinner-border me-2" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          <i className="ri-send-plane-line me-1 ri-lg"></i>
                        )}
                        {loading ? "Sending Code" : "Send Code"}
                      </button>
                    </div>
                  </form>
                </div>
              )}

              {currentStep === 2 && (
                <div>
                  <div className="row justify-content-center">
                    <div className="col-12 mb-0 text-center">
                      <div className="bs-stepper wizard-modern wizard-modern-example mt-2 text-center">
                        <div className="bs-stepper-header">
                          <div
                            className="step"
                            data-target="#account-details-modern"
                          >
                            <button
                              type="button"
                              className="step-trigger d-flex flex-column align-items-center"
                            >
                              <span className="bs-stepper-circle  bg-primary custom-stepper-circle"></span>
                              <span className="bs-stepper-label mt-2 text-center">
                                <span className="d-flex flex-column align-items-center">
                                  <span className="bs-stepper-title">
                                    User Details
                                  </span>
                                </span>
                              </span>
                            </button>
                          </div>

                          <div
                            className="progress mb-1 text-center rounded mt-0 mx-auto "
                            style={{ height: "9px", width: "58%" }}
                          >
                            <div
                              className="progress-bar rounded"
                              role="progressbar"
                              aria-valuenow="65"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <div
                            className="step"
                            data-target="#personal-info-modern"
                          >
                            <button
                              type="button"
                              className="step-trigger d-flex flex-column align-items-center"
                            >
                              <span className="bs-stepper-circle bg-white custom-stepper-circle d-flex justify-content-center align-items-center"></span>
                              <span className="bs-stepper-label mt-2 text-center">
                                <span className="d-flex flex-column gap-1">
                                  <span className="bs-stepper-title">
                                    Select Channel
                                  </span>
                                </span>
                              </span>
                            </button>
                          </div>
                        </div>
                        <p className="text-center mb-5  pb-3 mx-auto w-100">
                          Check out below plan for more details check out below
                          plan for more details Check out below plan for more
                          details check out below plan for more details
                        </p>
                      </div>
                    </div>
                  </div>
                  <form
                    id="verifyCodeForm"
                    className="row g-5"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleVerifyCode();
                    }}
                  >
                    <div className="col-12 col-md-6">
                      <div className="form-floating form-floating-outline">
                        <input
                          type="text"
                          id="api_id"
                          name="api_id"
                          className={`form-control ${
                            errors.api_id ? "is-invalid" : ""
                          }`}
                          value={formData.api_id}
                          onChange={handleInputChange}
                          placeholder="Enter API ID"
                        />
                        <label htmlFor="api_id">API ID</label>
                        {errors.api_id && (
                          <div className="invalid-feedback">
                            {errors.api_id}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-floating form-floating-outline">
                        <input
                          type="text"
                          id="mobile_number"
                          name="mobile_number"
                          className={`form-control ${
                            errors.mobile_number ? "is-invalid" : ""
                          }`}
                          value={formData.mobile_number}
                          onChange={handleInputChange}
                          placeholder="Enter Mobile Number"
                        />
                        <label htmlFor="mobile_number">Mobile Number</label>
                        {errors.mobile_number && (
                          <div className="invalid-feedback">
                            {errors.mobile_number}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-floating form-floating-outline">
                        <input
                          type="text"
                          id="api_hash"
                          name="api_hash"
                          className={`form-control ${
                            errors.api_hash ? "is-invalid" : ""
                          }`}
                          value={formData.api_hash}
                          onChange={handleInputChange}
                          placeholder="Enter Hash Code"
                        />
                        <label htmlFor="api_hash">Hash Code</label>
                        {errors.api_hash && (
                          <div className="invalid-feedback">
                            {errors.api_hash}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-floating form-floating-outline">
                        <input
                          type="text"
                          id="telegram_bot_token"
                          name="telegram_bot_token"
                          className={`form-control ${
                            errors.telegram_bot_token ? "is-invalid" : ""
                          }`}
                          value={formData.telegram_bot_token}
                          onChange={handleInputChange}
                          placeholder="Enter Bot Token"
                        />
                        <label htmlFor="telegram_bot_token">Bot Token</label>
                        {errors.telegram_bot_token && (
                          <div className="invalid-feedback">
                            {errors.telegram_bot_token}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-floating form-floating-outline">
                        <input
                          type="text"
                          id="login_code"
                          name="login_code"
                          className="form-control"
                          value={formData.login_code}
                          onChange={handleInputChange}
                          placeholder="Enter Login Code"
                        />
                        <label htmlFor="login_code">Login Code</label>
                      </div>
                    </div>

                    <div className="col-12 text-end">
                      <button type="submit" className="btn btn-primary">
                        <i className="ri-checkbox-circle-line ri-lg me-1"></i>{" "}
                        Verify Code
                      </button>
                    </div>
                  </form>
                </div>
              )}

              {currentStep === 3 && (
                <div>
                  <div className="row justify-content-center">
                    <div className="col-12 mb-0 text-center">
                      <div className="bs-stepper wizard-modern wizard-modern-example mt-2 text-center">
                        <div className="bs-stepper-header">
                          <div
                            className="step"
                            data-target="#account-details-modern"
                          >
                            <button
                              type="button"
                              className="step-trigger d-flex flex-column align-items-center"
                            >
                              <span className="bs-stepper-circle  bg-white custom-stepper-circle"></span>
                              <span className="bs-stepper-label mt-2 text-center">
                                <span className="d-flex flex-column align-items-center">
                                  <span className="bs-stepper-title">
                                    User Details
                                  </span>
                                </span>
                              </span>
                            </button>
                          </div>

                          <div
                            className="progress mb-1 text-center rounded mt-0 mx-auto "
                            style={{ height: "9px", width: "58%" }}
                          >
                            <div
                              className="progress-bar rounded"
                              role="progressbar"
                              aria-valuenow="65"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <div
                            className="step"
                            data-target="#personal-info-modern"
                          >
                            <button
                              type="button"
                              className="step-trigger d-flex flex-column align-items-center"
                            >
                              <span className="bs-stepper-circle bg-primary custom-stepper-circle d-flex justify-content-center align-items-center"></span>
                              <span className="bs-stepper-label mt-2 text-center">
                                <span className="d-flex flex-column gap-1">
                                  <span className="bs-stepper-title">
                                    Select Channel
                                  </span>
                                </span>
                              </span>
                            </button>
                          </div>
                        </div>
                        <p className="text-center mb-5  pb-3 mx-auto w-100">
                          Check out below plan for more details check out below
                          plan for more details Check out below plan for more
                          details check out below plan for more details
                        </p>
                      </div>
                    </div>
                  </div>
                  <form
                    id="selectChannelForm"
                    className="row g-5"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSelectChannel();
                    }}
                  >
                    <div className="row">
                      {channels.map((channel, index) => (
                        <div key={index} className="col-6 mb-3">
                          <div className="form-check ">
                            <button
                              type="button"
                              className={`btn rounded-pill w-100  d-flex align-items-center text-start ${
                                channel.selected
                                  ? "btn-label-primary"
                                  : "btn-outline-secondary"
                              }`}
                              onClick={() => toggleChannelSelection(index)}
                            >
                              <input
                                className="form-check-input me-2 ms-4"
                                type="checkbox"
                                id={`channel_${index}`}
                                checked={channel.selected || false}
                                onChange={() => toggleChannelSelection(index)}
                                style={{ pointerEvents: "none" }} // Prevent checkbox from being directly clicked
                              />
                              <span className="text-left w-100">
                                {channel.channel_username}
                              </span>
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="col-12 text-end d-flex justify-content-between">
                      <button
                        type="button"
                        className="btn rounded-pill btn-outline-secondary"
                        onClick={() => setCurrentStep(2)}
                      >
                        <i className="ri-arrow-left-line me-1"></i> Previous
                      </button>

                      <button
                        type="submit"
                        className="btn rounded-pill btn-success"
                      >
                        <i className="ri-check-double-line me-1 ri-lg"></i> Save
                        Details
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectChannel;





// import React, { useState, useRef } from "react";
// import axios from "axios";
// import config from "../../../component/config";
// import { Toast } from "primereact/toast";
// import { Link, useNavigate } from "react-router-dom";
// const SelectChannel = () => {
//   const [channels, setChannels] = useState([]);
//   const [currentStep, setCurrentStep] = useState(1);
//   const toast = useRef(null);
//   const [loading, setLoading] = useState(false);
//   const userId = localStorage.getItem("userId");
//   const navigate = useNavigate();
//   const [errors, setErrors] = useState({
//     api_id: "",
//     mobile_number: "",
//     api_hash: "",
//     telegram_bot_token: "",
//     login_code: "",
//   });

//   const [formData, setFormData] = useState({
//     api_id: "",
//     api_hash: "",
//     mobile_number: "",
//     telegram_bot_token: "",
//     login_code: "",
//   });
//   const validateFields = () => {
//     let newErrors = { ...errors };
//     let isValid = true;

//     Object.keys(formData).forEach((field) => {
//       const value = formData[field];

//       switch (field) {
//         case "api_id":
//           newErrors.api_id = value ? "" : "API ID is required";
//           break;
//         case "mobile_number":
//           newErrors.mobile_number = value ? "" : "Mobile Number is required";
//           break;
//         case "api_hash":
//           newErrors.api_hash = value ? "" : "API Hash is required";
//           break;
//         case "telegram_bot_token":
//           newErrors.telegram_bot_token = value
//             ? ""
//             : "Telegram Bot Token is required";
//           break;

//         default:
//           break;
//       }

//       if (newErrors[field]) {
//         console.log(`Validation failed for ${field}: ${newErrors[field]}`); // Debug log
//         isValid = false;
//       }
//     });

//     setErrors(newErrors);

//     return isValid;
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;

//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//     if (name === "login_code" && !/^\d{0,5}$/.test(value)) {
//       return; // Do nothing if the input is not valid
//     }
  

//     setErrors((prevErrors) => {
//       const updatedErrors = { ...prevErrors };

//       switch (name) {
//         case "api_id":
//           updatedErrors.api_id = value ? "" : "API ID is required";
//           break;
//         case "mobile_number":
//           updatedErrors.mobile_number = value
//             ? ""
//             : "Mobile Number is required";
//           break;
//         case "api_hash":
//           updatedErrors.api_hash = value ? "" : "API Hash is required";
//           break;
//         case "telegram_bot_token":
//           updatedErrors.telegram_bot_token = value
//             ? ""
//             : "Telegram Bot Token is required";
//           break;
//           case "login_code":
//             updatedErrors.login_code = /^\d{5}$/.test(value)
//               ? ""
//               : "Login Code must be 5 digits";
//             break;
//         default:
//           break;
//       }

//       return updatedErrors;
//     });
//   };

//   const handleSendCode = async () => {
//     if (!validateFields()) {
//       return;
//     }

//     const authToken = localStorage.getItem("authToken");
//     setLoading(true);

//     try {
//       console.log("Sending API request..."); // Debug log
//       const response = await axios.post(
//         `${config.apiDomain}/api/teacher/telegram/send_code`,
//         {
//           teacher_id: userId,
//           api_id: formData.api_id,
//           api_hash: formData.api_hash,
//           mobile_number: formData.mobile_number,
//           telegram_bot_token: formData.telegram_bot_token,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${authToken}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       console.log("API response received:", response); // Debug log

//       if (response.data.st === 1) {
//         if (response.data.st === 1) {
//           window.showToast("success", response.data.msg || "Code sent successfully");
//           setCurrentStep(2); // Move to the next step
//         } else {
//           window.showToast("error", response.data.msg || "Failed to send code");
//         }
//       } }catch (error) {
//         console.error("Error occurred during API request:", error); // Debug log
//         if (error.response && error.response.status === 401) {
//           navigate("/login");
//         } else {
//           const errorMsg = error.response?.data?.msg || "Failed to send code";
//           window.showToast("error", errorMsg);
//         }
//       } finally {
//         setLoading(false);
//       }
//     };              

//   const handleVerifyCode = async () => {
//     // Validate all fields
//     if (!validateFields()) return; // Check validation

//     const authToken = localStorage.getItem("authToken");

//     try {
//       const response = await axios.post(
//         `${config.apiDomain}/api/teacher/telegram/get_channel_list`,
//         {
//           teacher_id: userId,
//           api_id: formData.api_id,
//           api_hash: formData.api_hash,
//           mobile_number: formData.mobile_number,
//           telegram_bot_token: formData.telegram_bot_token,
//           login_code: formData.login_code,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${authToken}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       if (response.data.st === 1) {
//         setChannels(response.data.telegram_channels);
//         setCurrentStep(3);
//       } else {
        
//         window.showToast("error", response.data.msg || "Failed to verify code");

//       }
//     } catch (error) {
//       if (error.response && error.response.status === 401) {
//         console.error(
//           "Unauthorized access, redirecting to common login screen"
//         );
//         navigate("/login");
//       } else {
//         const errorMsg = error.response?.data?.msg || "Failed to verify code";
        
//         window.showToast("error", errorMsg || "Failed to verify code");

//       }
//     }
//   };

//   const handleSelectChannel = async () => {
//     const authToken = localStorage.getItem("authToken");

//     try {
//       const selectedChannels = channels
//         .filter((channel) => channel.selected)
//         .map((channel) => channel.channel_username);

//       const response = await axios.post(
//         `${config.apiDomain}/api/teacher/telegram/select_channel`,
//         {
//           teacher_id: userId,
//           channel_username: selectedChannels,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${authToken}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       if (response.data.st === 1) {
        
//         window.showToast("success", response.data.msg || "Channels selected successfully");
//         setIsModalOpen(false);
//       } else {
        
//         window.showToast("error", response.data.msg || "Failed to select channels");

//       }
//     } catch (error) {
//       if (error.response && error.response.status === 401) {
//         console.error(
//           "Unauthorized access, redirecting to common login screen"
//         );
//         navigate("/login");
//       } else {
//         const errorMsg =
//           error.response?.data?.msg || "Failed to select channels";
      
//         window.showToast("error", errorMsg || "Failed to select channels");

//       }
//     }
//   };

//   const toggleChannelSelection = (index) => {
//     const updatedChannels = [...channels];
//     updatedChannels[index].selected = !updatedChannels[index].selected;
//     setChannels(updatedChannels);
//   };
//   const resetForm = () => {
//     setFormData({
//       api_id: "",
//       mobile_number: "",
//       api_hash: "",
//       telegram_bot_token: "",
//       login_code: "",
//     });
//     setErrors({});
//     setCurrentStep(1);
//     setChannels([]);
//   };

//   // Add modal state
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const openModal = async () => {
//     const authToken = localStorage.getItem("authToken");
  
//     try {
//       const response = await axios.post(
//         "https://ghanish.in/api/teacher/telegram/telegram_access",
//         {
//           teacher_id: userId,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${authToken}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );
  
//       if (response.data.st === 1) {
//         if (response.data.telegram_access) {
//           setCurrentStep(3); // Directly open step 3
//           // Optionally, fetch the channel list if needed
//           const channelResponse = await axios.post(
//             `${config.apiDomain}/api/teacher/telegram/get_channel_list`,
//             {
//               teacher_id: userId,
//             },
//             {
//               headers: {
//                 Authorization: `Bearer ${authToken}`,
//                 "Content-Type": "application/json",
//               },
//             }
//           );
  
//           if (channelResponse.data.st === 1) {
//             setChannels(channelResponse.data.telegram_channels || []);
//           } else {
//             window.showToast("error", "Failed to fetch channels");
//           }
//         } else {
//           setCurrentStep(1); // Start from step 1
//         }
//         setIsModalOpen(true); // Open the modal
//       } else {
//         window.showToast("error", response.data.msg || "Failed to fetch access status");
//       }
//     } catch (error) {
//       console.error("Error checking telegram access:", error);
//       window.showToast("error", "Failed to check telegram access");
//     }
//   };
  

//   const closeModal = () => {
//     setIsModalOpen(false);
//     resetForm();
//   };

//   return (
//     <div>
//       <Toast ref={toast} />
      
//       <button
//         className="btn btn-outline-primary btn-sm"
//         onClick={openModal}
//       >
//         <i className="ri-user-settings-line text-primary ri-sm"></i>
//       </button>

//       <div
//         className={`modal ${isModalOpen ? 'show' : ''}`}
//         id="wizardModal"
//         tabIndex="-1"
//         aria-hidden={!isModalOpen}
//         style={{ 
//           display: isModalOpen ? 'block' : 'none',
//           zIndex: 1055  // Ensure modal is above backdrop
//         }}
//       >
//         {/* Backdrop with lower z-index */}
//         {isModalOpen && (
//           <div 
//             className="modal-backdrop fade show" 
//             style={{ zIndex: 1050 }}  // Ensure backdrop is below modal
//           />
//         )}
        
//         <div className="modal-dialog modal-lg modal-simple modal-edit-user" style={{ position: 'relative', zIndex: 1056 }}>
//           <div className="modal-content">
//             <div className="modal-body p-0">
//               <button
//                 type="button"
//                 className="btn-close"
//                 onClick={closeModal}
//                 aria-label="Close"
//               ></button>

//               {currentStep === 1 && (
//                 <div>
//                   <div className="row justify-content-center">
//                     <div className="col-12 mb-0 text-center">
//                       <div className="bs-stepper wizard-modern wizard-modern-example mt-2 text-center">
//                         <div className="bs-stepper-header">
//                           <div
//                             className="step"
//                             data-target="#account-details-modern"
//                           >
//                             <button
//                               type="button"
//                               className="step-trigger d-flex flex-column align-items-center"
//                             >
//                               <span className="bs-stepper-circle  bg-primary custom-stepper-circle"></span>
//                               <span className="bs-stepper-label mt-2 text-center">
//                                 <span className="d-flex flex-column align-items-center">
//                                   <span className="bs-stepper-title">
//                                     User Details
//                                   </span>
//                                 </span>
//                               </span>
//                             </button>
//                           </div>

//                           <div
//                             className="progress mb-1 text-center rounded mt-0 mx-auto "
//                             style={{ height: "9px", width: "58%" }}
//                           >
//                             <div
//                               className="progress-bar rounded"
//                               role="progressbar"
//                               aria-valuenow="65"
//                               aria-valuemin="0"
//                               aria-valuemax="100"
//                             ></div>
//                           </div>
//                           <div
//                             className="step"
//                             data-target="#personal-info-modern"
//                           >
//                             <button
//                               type="button"
//                               className="step-trigger d-flex flex-column align-items-center"
//                             >
//                               <span className="bs-stepper-circle bg-white custom-stepper-circle d-flex justify-content-center align-items-center"></span>
//                               <span className="bs-stepper-label mt-2 text-center">
//                                 <span className="d-flex flex-column gap-1">
//                                   <span className="bs-stepper-title">
//                                     Select Channel
//                                   </span>
//                                 </span>
//                               </span>
//                             </button>
//                           </div>
//                         </div>
//                         <p className="text-center mb-5  pb-3 mx-auto w-100">
//                           Check out below plan for more details check out below
//                           plan for more details Check out below plan for more
//                           details check out below plan for more details
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                   <form
//                     id="sendCodeForm"
//                     className="row g-5"
//                     onSubmit={(e) => {
//                       e.preventDefault();
//                       handleSendCode(); // Trigger API call on form submit
//                     }}
//                   >
//                     <div className="col-12 col-md-6">
//                       <div className="form-floating form-floating-outline">
//                         <input
//                           type="text"
//                           id="api_id"
//                           name="api_id"
//                           className={`form-control ${
//                             errors.api_id ? "is-invalid" : ""
//                           }`}
//                           value={formData.api_id}
//                           onChange={handleInputChange}
//                           placeholder="Enter API ID"
//                         />
//                         <label htmlFor="api_id">API ID</label>
//                         {errors.api_id && (
//                           <div className="invalid-feedback">
//                             {errors.api_id}
//                           </div>
//                         )}
//                       </div>
//                     </div>
//                     <div className="col-12 col-md-6">
//                       <div className="form-floating form-floating-outline">
//                         <input
//                           type="text"
//                           id="mobile_number"
//                           name="mobile_number"
//                           className={`form-control ${
//                             errors.mobile_number ? "is-invalid" : ""
//                           }`}
//                           value={formData.mobile_number}
//                           onChange={handleInputChange}
//                           placeholder="Enter Mobile Number"
//                         />
//                         <label htmlFor="mobile_number">Mobile Number</label>
//                         {errors.mobile_number && (
//                           <div className="invalid-feedback">
//                             {errors.mobile_number}
//                           </div>
//                         )}
//                       </div>
//                     </div>
//                     <div className="col-12 col-md-6">
//                       <div className="form-floating form-floating-outline">
//                         <input
//                           type="text"
//                           id="api_hash"
//                           name="api_hash"
//                           className={`form-control ${
//                             errors.api_hash ? "is-invalid" : ""
//                           }`}
//                           value={formData.api_hash}
//                           onChange={handleInputChange}
//                           placeholder="Enter Hash Code"
//                         />
//                         <label htmlFor="api_hash">Hash Code</label>
//                         {errors.api_hash && (
//                           <div className="invalid-feedback">
//                             {errors.api_hash}
//                           </div>
//                         )}
//                       </div>
//                     </div>
//                     <div className="col-12 col-md-6">
//                       <div className="form-floating form-floating-outline">
//                         <input
//                           type="text"
//                           id="telegram_bot_token"
//                           name="telegram_bot_token"
//                           className={`form-control ${
//                             errors.telegram_bot_token ? "is-invalid" : ""
//                           }`}
//                           value={formData.telegram_bot_token}
//                           onChange={handleInputChange}
//                           placeholder="Enter Bot Token"
//                         />
//                         <label htmlFor="telegram_bot_token">Bot Token</label>
//                         {errors.telegram_bot_token && (
//                           <div className="invalid-feedback">
//                             {errors.telegram_bot_token}
//                           </div>
//                         )}
//                       </div>
//                     </div>
//                     <div className="col-12 text-end">
//                       <button
//                         type="submit"
//                         className="btn btn-primary"
//                         disabled={loading}
//                       >
//                         {loading ? (
//                           <div className="spinner-border me-2" role="status">
//                             <span className="visually-hidden">Loading...</span>
//                           </div>
//                         ) : (
//                           <i className="ri-send-plane-line me-1 ri-lg"></i>
//                         )}
//                         {loading ? "Sending Code" : "Send Code"}
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//               )}

//               {currentStep === 2 && (
//                 <div>
//                   <div className="row justify-content-center">
//                     <div className="col-12 mb-0 text-center">
//                       <div className="bs-stepper wizard-modern wizard-modern-example mt-2 text-center">
//                         <div className="bs-stepper-header">
//                           <div
//                             className="step"
//                             data-target="#account-details-modern"
//                           >
//                             <button
//                               type="button"
//                               className="step-trigger d-flex flex-column align-items-center"
//                             >
//                               <span className="bs-stepper-circle  bg-primary custom-stepper-circle"></span>
//                               <span className="bs-stepper-label mt-2 text-center">
//                                 <span className="d-flex flex-column align-items-center">
//                                   <span className="bs-stepper-title">
//                                     User Details
//                                   </span>
//                                 </span>
//                               </span>
//                             </button>
//                           </div>

//                           <div
//                             className="progress mb-1 text-center rounded mt-0 mx-auto "
//                             style={{ height: "9px", width: "58%" }}
//                           >
//                             <div
//                               className="progress-bar rounded"
//                               role="progressbar"
//                               aria-valuenow="65"
//                               aria-valuemin="0"
//                               aria-valuemax="100"
//                             ></div>
//                           </div>
//                           <div
//                             className="step"
//                             data-target="#personal-info-modern"
//                           >
//                             <button
//                               type="button"
//                               className="step-trigger d-flex flex-column align-items-center"
//                             >
//                               <span className="bs-stepper-circle bg-white custom-stepper-circle d-flex justify-content-center align-items-center"></span>
//                               <span className="bs-stepper-label mt-2 text-center">
//                                 <span className="d-flex flex-column gap-1">
//                                   <span className="bs-stepper-title">
//                                     Select Channel
//                                   </span>
//                                 </span>
//                               </span>
//                             </button>
//                           </div>
//                         </div>
//                         <p className="text-center mb-5  pb-3 mx-auto w-100">
//                           Check out below plan for more details check out below
//                           plan for more details Check out below plan for more
//                           details check out below plan for more details
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                   <form
//                     id="verifyCodeForm"
//                     className="row g-5"
//                     onSubmit={(e) => {
//                       e.preventDefault();
//                       handleVerifyCode();
//                     }}
//                   >
//                     <div className="col-12 col-md-6">
//                       <div className="form-floating form-floating-outline">
//                         <input
//                           type="text"
//                           id="api_id"
//                           name="api_id"
//                           className={`form-control ${
//                             errors.api_id ? "is-invalid" : ""
//                           }`}
//                           value={formData.api_id}
//                           onChange={handleInputChange}
//                           placeholder="Enter API ID"
//                         />
//                         <label htmlFor="api_id">API ID</label>
//                         {errors.api_id && (
//                           <div className="invalid-feedback">
//                             {errors.api_id}
//                           </div>
//                         )}
//                       </div>
//                     </div>
//                     <div className="col-12 col-md-6">
//                       <div className="form-floating form-floating-outline">
//                         <input
//                           type="text"
//                           id="mobile_number"
//                           name="mobile_number"
//                           className={`form-control ${
//                             errors.mobile_number ? "is-invalid" : ""
//                           }`}
//                           value={formData.mobile_number}
//                           onChange={handleInputChange}
//                           placeholder="Enter Mobile Number"
//                         />
//                         <label htmlFor="mobile_number">Mobile Number</label>
//                         {errors.mobile_number && (
//                           <div className="invalid-feedback">
//                             {errors.mobile_number}
//                           </div>
//                         )}
//                       </div>
//                     </div>
//                     <div className="col-12 col-md-6">
//                       <div className="form-floating form-floating-outline">
//                         <input
//                           type="text"
//                           id="api_hash"
//                           name="api_hash"
//                           className={`form-control ${
//                             errors.api_hash ? "is-invalid" : ""
//                           }`}
//                           value={formData.api_hash}
//                           onChange={handleInputChange}
//                           placeholder="Enter Hash Code"
//                         />
//                         <label htmlFor="api_hash">Hash Code</label>
//                         {errors.api_hash && (
//                           <div className="invalid-feedback">
//                             {errors.api_hash}
//                           </div>
//                         )}
//                       </div>
//                     </div>
//                     <div className="col-12 col-md-6">
//                       <div className="form-floating form-floating-outline">
//                         <input
//                           type="text"
//                           id="telegram_bot_token"
//                           name="telegram_bot_token"
//                           className={`form-control ${
//                             errors.telegram_bot_token ? "is-invalid" : ""
//                           }`}
//                           value={formData.telegram_bot_token}
//                           onChange={handleInputChange}
//                           placeholder="Enter Bot Token"
//                         />
//                         <label htmlFor="telegram_bot_token">Bot Token</label>
//                         {errors.telegram_bot_token && (
//                           <div className="invalid-feedback">
//                             {errors.telegram_bot_token}
//                           </div>
//                         )}
//                       </div>
//                     </div>
//                     <div className="col-12 col-md-6">
//   <div className="form-floating form-floating-outline">
//     <input
//       type="text"
//       id="login_code"
//       name="login_code"
//       className={`form-control ${errors.login_code ? "is-invalid" : ""}`}
//       value={formData.login_code}
//       onChange={handleInputChange}
//       placeholder="Enter Login Code"
//       maxLength="5" // Prevent typing more than 5 characters
//     />
//     <label htmlFor="login_code">Login Code</label>
//     {errors.login_code && (
//       <div className="invalid-feedback">{errors.login_code}</div>
//     )}
//   </div>
// </div>


//                     <div className="col-12 text-end">
//                       <button type="submit" className="btn btn-primary">
//                         <i className="ri-checkbox-circle-line ri-lg me-1"></i>{" "}
//                         Verify Code
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//               )}

//               {currentStep === 3 && (
//                 <div>
//                   <div className="row justify-content-center">
//                     <div className="col-12 mb-0 text-center">
//                       <div className="bs-stepper wizard-modern wizard-modern-example mt-2 text-center">
//                         <div className="bs-stepper-header">
//                           <div
//                             className="step"
//                             data-target="#account-details-modern"
//                           >
//                             <button
//                               type="button"
//                               className="step-trigger d-flex flex-column align-items-center"
//                             >
//                               <span className="bs-stepper-circle  bg-white custom-stepper-circle"></span>
//                               <span className="bs-stepper-label mt-2 text-center">
//                                 <span className="d-flex flex-column align-items-center">
//                                   <span className="bs-stepper-title">
//                                     User Details
//                                   </span>
//                                 </span>
//                               </span>
//                             </button>
//                           </div>

//                           <div
//                             className="progress mb-1 text-center rounded mt-0 mx-auto "
//                             style={{ height: "9px", width: "58%" }}
//                           >
//                             <div
//                               className="progress-bar rounded"
//                               role="progressbar"
//                               aria-valuenow="65"
//                               aria-valuemin="0"
//                               aria-valuemax="100"
//                             ></div>
//                           </div>
//                           <div
//                             className="step"
//                             data-target="#personal-info-modern"
//                           >
//                             <button
//                               type="button"
//                               className="step-trigger d-flex flex-column align-items-center"
//                             >
//                               <span className="bs-stepper-circle bg-primary custom-stepper-circle d-flex justify-content-center align-items-center"></span>
//                               <span className="bs-stepper-label mt-2 text-center">
//                                 <span className="d-flex flex-column gap-1">
//                                   <span className="bs-stepper-title">
//                                     Select Channel
//                                   </span>
//                                 </span>
//                               </span>
//                             </button>
//                           </div>
//                         </div>
//                         <p className="text-center mb-5  pb-3 mx-auto w-100">
//                           Check out below plan for more details check out below
//                           plan for more details Check out below plan for more
//                           details check out below plan for more details
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                   <form
//                     id="selectChannelForm"
//                     className="row g-5"
//                     onSubmit={(e) => {
//                       e.preventDefault();
//                       handleSelectChannel();
//                     }}
//                   >
//                     <div className="row">
//                       {channels.map((channel, index) => (
//                         <div key={index} className="col-6 mb-3">
//                           <div className="form-check ">
//                             <button
//                               type="button"
//                               className={`btn rounded-pill w-100  d-flex align-items-center text-start ${
//                                 channel.selected
//                                   ? "btn-label-primary"
//                                   : "btn-outline-secondary"
//                               }`}
//                               onClick={() => toggleChannelSelection(index)}
//                             >
//                               <input
//                                 className="form-check-input me-2 ms-4"
//                                 type="checkbox"
//                                 id={`channel_${index}`}
//                                 checked={channel.selected || false}
//                                 onChange={() => toggleChannelSelection(index)}
//                                 style={{ pointerEvents: "none" }} // Prevent checkbox from being directly clicked
//                               />
//                               <span className="text-left w-100">
//                                 {channel.channel_username}
//                               </span>
//                             </button>
//                           </div>
//                         </div>
//                       ))}
//                     </div>

//                     <div className="col-12 text-end d-flex justify-content-between">
//                       <button
//                         type="button"
//                         className="btn rounded-pill btn-outline-secondary"
//                         onClick={() => setCurrentStep(2)}
//                       >
//                         <i className="ri-arrow-left-line me-1"></i> Previous
//                       </button>

//                       <button
//                         type="submit"
//                         className="btn rounded-pill btn-success"
//                       >
//                         <i className="ri-check-double-line me-1 ri-lg"></i> Save
//                         Details
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SelectChannel;
