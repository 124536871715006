import React, { useEffect, useState ,useRef} from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import AdminHeader from "./AdminHeader";
import Footer from "../../../component/Footer"
import AdminSubHeader from "./AdminSubHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import axios from "axios";
import { Tooltip } from "primereact/tooltip";
import config from "../../../component/config";
import CardLinks from "../../../component/CardLinks";
import { Toast } from "primereact/toast";
import SubscriptionBadge from "../../../component/SubscriptionBadge";
const TeacherReportDetails = () => {
  const { teacherId, sellDate } = useParams();
  const navigate = useNavigate();
  const toast = useRef(null);
  // Fetch teacher_id and sell_month from URL params
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(true);
  const [backClicked, setBackClicked] = useState(false);
  const [error, setError] = useState(null);
  const [summary, setSummary] = useState({
    total_trades_count: 0,
    total_profitable_trades: 0,
    total_losing_trades: 0,
    total_commission: 0.0,
  });

   
  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  const fetchData = async () => {
    setLoading(true);
    setError(null);
    const authToken = localStorage.getItem("authToken");
    const userId = localStorage.getItem("userId");
     if (!authToken || !userId) {
    navigate("/login");
      return;
    }
    try {
      const response = await axios.post(
        `${config.apiDomain}/api/admin/teacher_trade_details`,
        {
          teacher_id: teacherId,
          sell_date: sellDate,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
          },
        }
      );

      if (response.data) {
        setData(response.data.trades);
        
      } else {
        setError(new Error("No data found"));
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access, redirecting to common login screen");
        navigate("/login"); // Redirect to common login screen on 401 error
      } else {
        console.error("Unauthorized access, redirecting to common login screen");
      
      }
    } finally {
      setLoading(false);
    }
  };



  const handleRefresh = async () => {
    setLoading(true);
    setIsRefreshing(true);
    setError(null);
  
    const authToken = localStorage.getItem("authToken");
    const userId = localStorage.getItem("userId");
  
    if (!authToken || !userId) {
      navigate("/login");
      return;
    }
  
    try {
      const response = await axios.post(
        `${config.apiDomain}/api/admin/teacher_trade_details`,
        {
          teacher_id: teacherId,
          sell_date: sellDate,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
  
      const { st, msg, trades } = response.data;
  
      if (st === 1) {
        setData(trades);
        // window.showToast("success", msg || "Data fetched successfully.");
      } else {
        switch (st) {
          case 2:
            setError(new Error(msg || "Critical issue encountered"));
            window.showToast("danger", msg || "An error occurred. Please check.");
            break;
          case 3:
            setError(new Error(msg || "Warning: Potential issue"));
            window.showToast("warning", msg || "Warning: Please review the details.");
            break;
          case 4:
            setError(new Error(msg || "Info: Additional information"));
            window.showToast("info", msg || "Info: Check the provided information.");
            break;
          default:
            const fallbackMsg = msg || "Something went wrong. Contact Support.";
            setError(new Error(fallbackMsg));
            window.showToast("error", fallbackMsg);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
  
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access, redirecting to common login screen");
        navigate("/login");
      } else {
        const errorMsg = error.response?.data?.msg || error.message || "Something went wrong. Contact Support.";
        setError(new Error(errorMsg));
        window.showToast("error", errorMsg);
      }
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };
  
  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  useEffect(() => {
    fetchData();
  }, [teacherId, sellDate]);

  
  const buyPriceTemplate = (rowData) => {
    return ` ${rowData.buy_price} Rs.`;
  };

  const sellPriceBodyTemplate = (rowData) => {
    return ` ${rowData.sell_price} Rs.`;
  };

  const PLTemplate = (rowData) => {
    return ` ${rowData.pandl} Rs.`;
  };

  const PLTotalBodyTemplate = (rowData) => {
    return ` ${rowData.pandl_total} Rs.`;
  };

  const PLPercentTemplate = (rowData) => {
    return ` ${rowData.pandl_percent} %`;
  };

  const CommissionBodyTemplate = (rowData) => {
    return ` ${rowData.commission} %`;
  };
  const buyColumnTemplate = (rowData) => {
    return (
      <div>
        <div className="mb-1">
          Price: <strong>{rowData.buy_price} </strong> | Lot:
          <strong>
            {" "}
            {rowData.buy_lotsize} ({rowData.buy_stock_quantity})
          </strong>
          
        </div>
        <div className="mb-1">
         
          {rowData.buy_datetime} | {brokerNameMap[rowData.broker_type]} | <span className="text-capitalize">{rowData.entry_type}</span>
        </div>
        <div className="fw-bold"> {rowData.stock_symbol}</div>
      </div>
    );
  };

  const sellColumnTemplate = (rowData) => {
    return (
      <div>
        <div className="mb-1">
          Price: <strong>{rowData.sell_price} </strong> | Lot:
          <strong>
            {" "}
            {rowData.sell_lotsize} ({rowData.sell_stock_quantity})
          </strong>
   
        </div>
        <div className="mb-1">
       
          {rowData.sell_datetime} | {brokerNameMap[rowData.broker_type]} | <span className="text-capitalize">{rowData.exit_type}</span>
        </div>
        <div className="fw-bold"> {rowData.stock_symbol}</div>
      </div>
    );
  };

  const brokerNameMap = {
    angle_one: "AngelOne",
    dhan: "Dhan",
  };
  return (
    <>
      <AdminHeader />
      <AdminSubHeader />
      <Toast ref={toast} />
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
        <div className="d-flex justify-content-between align-items-center">
        <ol className="breadcrumb breadcrumb-style1 text-black mb-3">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
           
            <li className="breadcrumb-item">
              <Link to="/admin/teacher_list" className="text-black">
                Teacher Report
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/teacher_report" className="text-black">
              Monthwise 
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/teacher_report_datewise" className="text-black">
              Datewise  
              </Link>
            </li>
            <li className="breadcrumb-item active text-secondary" aria-current="page">
            {sellDate}
            </li>
          </ol>
          <SubscriptionBadge></SubscriptionBadge>
          </div>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center">
            <div className="col-3 text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-7 text-center mb-5">
              <h5 className="mb-0"> {sellDate}</h5>
            </div>
            <div className="col-2"></div>
          </div>


          

          <div className="row align-items-center mb-3">
  <div className="col-12 col-md-6">
    <div className="d-flex align-items-center">
      <IconField iconPosition="left">
        <InputIcon className="ri ri-search-line"></InputIcon>
        <InputText
          type="search"
          placeholder="Search"
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          className="rounded"
        />
      </IconField>

      {loading ? (
              <div className="d-flex align-items-center">
                <i
                  className={`ri-refresh-line ri-lg ms-3 p-text-secondary ${
                    isRefreshing ? "rotate" : ""
                  }`}
                  role="status"
                ></i>
              </div>
            ) : (
              <div className="mt-3">
                <Tooltip target=".custom-target-icon" />
                <i
                  className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
                  role="button"
                  data-pr-tooltip="Reload"
                  onClick={handleRefresh}
                  data-pr-position="top"
                ></i>
              </div>
            )}
    </div>
  </div>

  <div className="col-12 col-md-6 text-md-end text-end mt-3 mt-md-0">
    <strong className="fs-4">{sellDate}</strong>
  </div>
</div>

          <DataTable
            className="custom-column-border"
            value={data}
            paginator
            rows={20}
            showGridlines
            loading={loading}
            globalFilter={globalFilter}
            emptyMessage="No records found"
          >
            <Column
    align="start"
      className="custom-column-border w-50"
    header="Entry"
    body={buyColumnTemplate}
    // style={{ width: '200px' }} 
  ></Column>

  <Column
    align="start"
     className="custom-column-border w-50"
    header="Exit"
    body={sellColumnTemplate}
  ></Column>
            <Column
              align="center"
              className="custom-column-border "
              field="pandl"
              header="P&L"
              body={PLTemplate}
            ></Column>
            <Column
              align="center"
              className="custom-column-border"              field="pandl_total"
              header="P&L Total"
              body={ PLTotalBodyTemplate} 
            ></Column>
            <Column
              align="center"
              className="custom-column-border"              field="pandl_percent"
              header="P&L Percent"
              body={PLPercentTemplate}
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="commission"
              header="Commission "
              body={CommissionBodyTemplate}
            ></Column>
          </DataTable>
        </div>
        <CardLinks></CardLinks>
      </div>

      <Footer />
    </>
  );
};

export default TeacherReportDetails;
