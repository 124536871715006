import React from "react";
import latecy from "../../../assets/img/products/latecy.png"

const AlgoTradingTimeScale = () => {
  return (
    <div>
    <section id="landingFeatures" className="landing-features bg-white pt-10 mb-5"> 
   <div className="container-xxl">
  {/* Main Row to contain both col-4 and col-8 */}
  <div className="row mb-5">
    {/* Heading Section */}
    <div className="col-lg-5">
      <h2 className="fw-bold">Algo Trading Time Scales</h2>
      <p>
        Algo trading is implemented across several time scales based on an individual’s investment needs. The time scale is just a factor of an individual’s choice and the type of trader; it can be changed accordingly from person to person. The factors that could affect the decision are events, market conditions, market segments, and many other factors.
      </p>
      <p className="fw-bold">
        Let us discuss the different time scales mentioned in algorithmic trading:
      </p>
    </div>

    {/* Content Section */}
    <div className="col-lg-7">
      <div className="row">
        <div className="col-lg-6 mb-4">
        <div className="text-center text-md-start">
        <i className="ri ri-bar-chart-line fs-1 me-3"></i> 
            <div>
              <h4 className="fw-bold">Low-Latency Trading</h4>
              <p>Slow execution can lead to missed opportunities, and traders struggling with latency may be losing money.</p>
            </div>
          </div>
        </div>

        <div className="col-lg-6 mb-4">
  <div className="text-center text-md-start">
    <i className="ri ri-funds-line fs-1 me-3"></i> {/* Icon */}
    <div> {/* Text */}
      <h4 className="fw-bold">High-Frequency Trading</h4>
      <p>High-frequency trading (HFT) is algorithmic trading implemented by relatively high-speed execution.</p>
    </div>
  </div>
</div>


        {/* Second Column */}
        <div className="col-lg-6 mb-4">
        <div className="text-center text-md-start">
        <i className="ri ri-stock-line fs-1 me-3"></i> 
            <div>
              <h4 className="fw-bold">Intraday</h4>
              <p>Many intraday traders place orders between seconds and minutes through algorithmic trading.</p>
            </div>
          </div>
        </div>

        <div className="col-lg-6 mb-4">
        <div className="text-center text-md-start">
        <i className="ri ri-funds-box-line fs-1 me-3"></i> 
            <div>
              <h4 className="fw-bold">Swing Trading</h4>
              <p>Swing traders take advantage of the market’s short- to medium-term price movements.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </section>


    <section id="landingFeatures" className="landing-features bg-body pt-8 pb-8"> 

    <div className="container-xxl ">
      <div className="row align-items-center">
        {/* Left Column: Image */}
        <div className="col-lg-5 text-center ">
          <img
            src={latecy}
            alt="Algo Trading Legal "
            className=" img-fluid"
          />
        </div>

        {/* Right Column: Text Content */}
        <div className="col-lg-7">
          <h2 className="fw-bold mb-0">Algo Trading in India and its Legality</h2>
          <p>
            The SEBI (Securities and Exchange Board of India) approved Algo trading in India in 2008. In the beginning, only institutional investors, such as mutual funds and insurance companies, were allowed to do it, but now, even retail investors are allowed to deploy their strategy completely onto Algo software. Lots of broker and fintech companies offer a way to do Algo trading through an Application Programming Interface (API).
          </p>
          <p>
            Users can either create their own trading strategy based on their conditions or pick one that's already set up by the Algo platform providers like Bigul. According to the 2018 report from NIFM, 50% of all clients' trades are done through Algo software, and about 40% of proprietary trades use it, too. In more developed markets, Algo trading makes up about 80% of all trades.
          </p>
        </div>
      </div>
    </div>
    </section>
</div>
  );
};

export default AlgoTradingTimeScale;
