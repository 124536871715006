import React from "react";


const AlgoTradingRequirement = () => {
  return (
    <section id="landingFeatures" className="landing-features bg-body pt-10 mb-5"> 
    <div className="container-xxl  ">
      {/* Heading Section */}
      <div className="row mb-5">
        <div className="col-lg-12 text-start">
          <h2 className="fw-bold mb-0">Technical Requirements for Algorithmic Trading</h2>
          <p className="mt-3">
            Algo trading is completely based on a program, which means, obviously, there is a technical portion in the setup of the environment. Implementing the algo using a computer program is the last component of algorithmic trading, accompanied by backtesting (testing out the algorithmic strategy on historical periods of past stock-market performance to check the performance to determine whether the strategy is profitable or not). The challenge is to transform the created strategy into an integrated program that has access to a broker side for punching the orders. The following are some prerequisites for algorithmic trading:
          </p>
        </div>
      </div>

      {/* Requirements Grid Section */}
      <div className="row">
        {/* First Row */}
        <div className="col-md-4 mb-4">
          <div className="card p-3 shadow-sm ">
            <h4 className="fw-bold"> <i className="ri  ri-computer-line text-success me-2"></i>Computer Skills</h4>
            <p>
              Computer-programming knowledge is necessary to self-build an algo strategy program, hire a programmer who can code your strategy and deploy it on the server, or use pre-made trading software.
            </p>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card p-3 shadow-sm">
            <h4 className="fw-bold"><i className="ri ri-wifi-line me-2 text-danger"></i>Internet Connection</h4>
            <p>
              It is necessary to have internet connectivity and access to trading platforms to place orders. Keep in mind the server connectivity from the cloud and broker should also be fine for smooth transactions.
            </p>
          </div>
        </div>

        {/* Second Row */}
        <div className="col-md-4 mb-4">
          <div className="card p-3 shadow-sm ">
            <h4 className="fw-bold"><i className="ri ri-database-line me-2 text-info"></i>Access to Market Data & API</h4>
            <p>
              The programmer should have API keys and proper access to market data, which must be monitored by the algorithm for continuous looking for opportunities to place orders.
            </p>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card p-3 shadow-sm ">
            <h4 className="fw-bold"><i className="ri ri-history-line me-2 text-warning"></i>Historical Data</h4>
            <p>
              An algo creator should have past data to run and test the available historical data, depending on the various market conditions and complexity of rules implemented in the algorithm.
            </p>
          </div>
        </div>

        {/* Third Row */}
        <div className="col-md-4 mb-4">
          <div className="card p-3 shadow-sm ">
            <h4 className="fw-bold"><i className="ri ri-flask-line me-2 text-info"></i>Ability to Test</h4>
            <p>
              The ability and infrastructure to backtest the algo system once it is built before it goes live on real markets. It is necessary to backtest the strategy and see the RR, POP and other factors before making its algorithm.
            </p>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card p-3 shadow-sm ">
            <h4 className="fw-bold"><i className="ri ri-rocket-line me-2 text-success"></i>High Execution Process</h4>
            <p>
              Utilize distributed databases and parallel processing techniques to store and analyze large volumes of market data efficiently. Cloud servers would be the best choice for fast execution of orders.
            </p>
          </div>
        </div>
      </div>
    </div>
    </section>
  );
};

export default AlgoTradingRequirement;
