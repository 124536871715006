import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import Footer from "../../../component/Footer";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Modal } from "react-bootstrap";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import axios from "axios";
import { Tooltip } from "primereact/tooltip";
import config from "../../../component/config";
import Header from "../component/Header";
import SubHeader from "../component/SubHeader";
import { Toast } from "primereact/toast";
import CardLinks from "../../../component/CardLinks";
import SubscriptionBadge from "../../../component/SubscriptionBadge";
const MyReport = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId"); 
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [backClicked, setBackClicked] = useState(false);
  const toast = useRef(null);
  const [isRefreshing,setIsRefreshing] = useState(true);
  const [summary, setSummary] = useState({
    total_trades_count: 0,
    total_profitable_trades: 0,
    total_losing_trades: 0,
    total_commission: 0.0,
  });
  const [month, setMonth] = useState(""); // State for month

  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      const authToken = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");

      if (!authToken || !userId) {
        navigate("/login");
        return;
      }

      const response = await axios.post(
        `${config.apiDomain}/api/common/my_report`,
        {
          user_id: userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data && response.data.st === 1) {
        setData(response.data.completed_trades_per_month);
        setSummary(response.data.completed_trades);
      } else {
        setError(new Error("No data found"));
      }
    } catch (error) {
      console.clear(); 
     
      if (error.response && error.response.status === 401) {

        navigate("/login"); 
      } else {
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = async () => {
    setLoading(true);
    setError(null);
    setIsRefreshing(true);
    
    try {
      const authToken = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");
      if (!authToken || !userId) {
        navigate("/login");
        return;
      }
  
      const response = await axios.post(
        `${config.apiDomain}/api/common/my_report`,
        { user_id: userId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
  
      const { st, msg, completed_trades_per_month, completed_trades } = response.data;
  
      // Handle different `st` values to display custom toasts
      switch (st) {
        case 1:
          setData(completed_trades_per_month);
          setSummary(completed_trades);
          break;
        case 2:
          setError(new Error(msg || "Warning"));
          window.showToast("warning", msg || "Warning: Please check the details.");
          break;
        case 3:
        case 4:
          setError(new Error(msg || "Danger: Server Error"));
          window.showToast("error", msg || "An error occurred. Please try again.");
          break;
        default:
          setError(new Error("Failed to fetch data"));
          window.showToast("error", "Something went wrong. Contact Support.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
  
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access, redirecting to common login screen");
        navigate("/login"); // Redirect on 401 Unauthorized
      } else {
        const errorMsg = error.response?.data?.msg || error.message || "Something went wrong. Contact Support.";
        setError(new Error(errorMsg));
        window.showToast("error", errorMsg);
      }
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };

  useEffect(() => {
    fetchData(); 
  }, []); 

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const handleViewReport = (rowData) => {
    navigate(`/teacher/my_report_Datewise`, {
      state: { userId, month: rowData.month_name },
    });
  };


  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const [showPopup, setShowPopup] = useState(false); 

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();


      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 30)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000); // Every minute


    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

 
  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success"; 
    } else if (hours === 15) {
      return "danger"; 
    }
    return "secondary"; 
  };
  const CommissionBodyTemplate = (rowData) => {
    return ` ${rowData.commission} Rs.`;
  };
  return (
    <>
      <Toast ref={toast} />
      <Header></Header>
      <SubHeader></SubHeader>
      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
        <div className="d-flex justify-content-between align-items-center">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/teacher/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>

            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              My Report
            </li>
          </ol>
          <SubscriptionBadge></SubscriptionBadge>
          </div>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center">
            <div className="col-3 text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-6 text-center mb-5">
              <h5 className="mb-0">My Report</h5>
            </div>
            <div className="col-12 col-md-3 col-lg-3 text-end mb-5">
              <Link to="/teacher/student_report">
                <button className="btn rounded-pill btn-outline-secondary btn-xs">
                  <span className="text-black">
                    <i className="ri-group-line me-1 ri-lg"></i>{" "}
                    <span>Student Report</span>
                  </span>
                </button>
              </Link>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-md-3 col-6">
              <h4 className="mb-0">{summary.total_trades_count || 0}</h4>
              <p className="mt-0">Total Trades</p>
            </div>
            <div className="col-md-3 col-6">
              <h4 className="mb-0">{summary.total_profitable_trades || 0}</h4>
              <p className="mt-0">Profitable Trades</p>
            </div>
            <div className="col-md-3 col-6">
              <h4 className="mb-0">{summary.total_losing_trades || 0}</h4>
              <p className="mt-0">Losing Trades</p>
            </div>
            <div className="col-md-3 col-6">
              <h4 className="mb-0">{summary.total_commission || 0} Rs.</h4>
              <p className="mt-0">Commission</p>
            </div>
          </div>

          <div className="d-flex justify-content-start mb-3">
            <IconField iconPosition="left">
              <InputIcon className="ri ri-search-line"> </InputIcon>
              <InputText
                type="search"
                placeholder="Search"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
                className="rounded"
              />
            </IconField>
            {loading ? (
              <div className="d-flex align-items-center">
                <i
                  className={`ri-refresh-line ri-lg ms-3 p-text-secondary ${
                    isRefreshing ? "rotate" : ""
                  }`}
                  role="status"
                ></i>
              </div>
            ) : (
              <div className="mt-3">
                <Tooltip target=".custom-target-icon" />
                <i
                  className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
                  role="button"
                  data-pr-tooltip="Reload"
                  onClick={handleRefresh}
                  data-pr-position="top"
                ></i>
              </div>
            )}
          </div>
          <DataTable
            className="custom-column-border"
            align="center"
            value={data}
            paginator
            rows={20}
            showGridlines
            loading={loading}
            globalFilter={globalFilter}
            emptyMessage="No records found"
          >
            <Column
              align="center"
              className="custom-column-border"
              field="month_name"
              header="Month"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="trades_count"
              header="Total Trades"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="profitable_trades"
              header="Profitable Trades"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="losing_trades"
              header="Losing Trades"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="commission"
              header="Commission"
              body={CommissionBodyTemplate}
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              header="Actions"
              body={(rowData) => (
                <Button
                  onClick={() => handleViewReport(rowData)}
                  className="btn btn-info custom-btn-action1 custom-target-icon"
                  data-pr-tooltip="View Record"
                  data-pr-position="top"
                >
                  <i className="ri-gallery-view-2"></i>
                </Button>
              )}
            ></Column>
          </DataTable>
        </div>
        <CardLinks></CardLinks>
      </div>

      <Footer />
    </>
  );
};

export default MyReport;

const getColorModalClass = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9 || hours === 15) {
    return hours === 9 ? "modal-green" : "modal-red"; 
  }
  return "";
};

const getModalTitle = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is Open!";
  } else if (hours === 15) {
    return "Market is Closed!";
  }
  return "";
};

const getModalBody = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is currently open. Take necessary actions.";
  } else if (hours === 15) {
    return "Market is currently closed. Come back tomorrow.";
  }
  return "";
};
