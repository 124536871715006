import React, { useState, useEffect } from "react";
import "./Blinker.css";

function Blinker({
  baseColor = "#03A9F4", // sky blue
  pulseColor = "#00FF00", // green
  baseSize = "15px",
  pulseSize = "30px",
}) {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  const isMarketOpen = () => {
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();
    const currentDay = currentTime.getDay();

    // Market open from 9:15 AM to 3:15 PM
    const marketOpenHour = 9;
    const marketOpenMinute = 15;
    const marketCloseHour = 15;
    const marketCloseMinute = 30;

    // Check if today is Saturday (6) or Sunday (0)
    if (currentDay === 0 || currentDay === 6) {
      return false;
    }

    // Check if the current time is within market hours
    if (
      (currentHour > marketOpenHour ||
        (currentHour === marketOpenHour &&
          currentMinute >= marketOpenMinute)) &&
      (currentHour < marketCloseHour ||
        (currentHour === marketCloseHour && currentMinute <= marketCloseMinute))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const circleStyle = {
    width: `${baseSize}px`,
    height: `${baseSize}px`,
    backgroundColor: isMarketOpen() ? baseColor : "#FFA500", // blue when market is open, orange when market is closed
    position: "relative", // Added position relative to fix the position of the circle
  };

  const blinkStyle = {
    width: `${pulseSize}px`,
    height: `${pulseSize}px`,
    backgroundColor: isMarketOpen() ? pulseColor : "#FFA500", // green when market is open, orange when market is closed
    position: "absolute", // Added position absolute to fix the position of the blinking circle
    left: `${(baseSize - pulseSize) / 2}px`,
    top: `${(baseSize - pulseSize) / 2}px`,
  };

  return (
    <>
      <div className="blinker-container mb-2 ms-5 ps-2 d-flex align-items-center">
        <div className="blinker-circle" style={circleStyle}>
          {isMarketOpen() && (
            <div className="blinker-blink" style={blinkStyle}></div>
          )}
        </div>
        <div
          className={`ms-2 ps-1 text-wrap ${
            isMarketOpen() ? "" : "text-body-tertiary"
          }`}
        >
          {isMarketOpen() ? "Market Open" : "Market Closed"}
        </div>
      </div>
    </>
  );
}

export default Blinker;