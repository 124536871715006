  import React, { useEffect, useState, useRef } from "react";
  import { DataTable } from "primereact/datatable";
  import { Column } from "primereact/column";
  import { InputText } from "primereact/inputtext";
  import AdminHeader from "./AdminHeader";
  import Footer from "../../../component/Footer";
  import AdminSubHeader from "./AdminSubHeader";
  import { Link, useNavigate } from "react-router-dom";
  import { Modal } from "react-bootstrap";
  import { IconField } from "primereact/iconfield";
  import { InputIcon } from "primereact/inputicon";
  import axios from "axios";
  import config from "../../../component/config";
  import { Tooltip } from "primereact/tooltip";
  import { Toast } from "primereact/toast";
  import { classNames } from "primereact/utils";
  import CardLinks from "../../../component/CardLinks";
  import angelonelogo from "../../../assets/logo/angelonelogo.png"
  import dhanlogo from "../../../assets/logo/dhanlogo.jpeg"
  import SubscriptionBadge from "../../../component/SubscriptionBadge";
  const ManageTeacher = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [backClicked, setBackClicked] = useState(false);
    const [globalFilter, setGlobalFilter] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const toast = useRef(null);
    const [isRefreshing,setIsRefreshing] =useState(true);

    const [showModal, setShowModal] = useState(false);
    const [userIdToToggle, setUserIdToToggle] = useState(null);
    const [activeStatusCount, setActiveStatusCount] = useState(0);
    const [tradeStatusCount, setTradeStatusCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [rowToDelete, setRowToDelete] = useState(null);
    const [brokerConnectionStatus, setBrokerConnectionStatus] = useState(null);

    const [teacherCount, setTeacherCount] = useState({
      total_teacher_count: 0,
      active_teacher_count: 0,
      inactive_teacher_count: 0,
    });
    useEffect(() => {
      const status = JSON.parse(localStorage.getItem("brokerConnectionStatus"));
      setBrokerConnectionStatus(status);
    }, []);

    const handleToggleTrade = async (userId) => {
      try {
        const authToken = localStorage.getItem("authToken");
    
        if (!authToken) {
          throw new Error("Auth token not found");
        }
    
        const response = await axios.post(
          `${config.apiDomain}/api/admin/teacher_trade_switch`,
          { teacher_id: userId },
          { headers: { Authorization: `Bearer ${authToken}` } }
        );
    
        const { st, msg } = response.data;
    
        if (st === 1) {
          const updatedData = data.map((item) => {
            if (item.user_id === userId) {
              return { ...item, trade_status: !item.trade_status };
            }
            return item;
          });
    
          setData(updatedData);
          window.showToast("success", msg || "Trade status updated successfully!");
          await fetchData();
        } else {
          switch (st) {
            case 2:
              window.showToast("danger", msg || "Error updating trade status.");
              break;
            case 3:
              window.showToast("warning", msg || "Warning: Check trade status update.");
              break;
            case 4:
              window.showToast("info", msg || "Info: Trade status update with additional notes.");
              break;
            default:
              window.showToast("error", "Something went wrong. Contact Support.");
          }
        }
      } catch (error) {
        console.error("Error switching trade status:", error);
    
        if (error.response && error.response.status === 401) {
          console.log("Unauthorized access detected. Redirecting to login...");
          navigate("/login");
        } else {
          const errorMsg = error.response?.data?.msg || "Something went wrong. Contact Support.";
          window.showToast("error", errorMsg);
        }
      }
    };
    
    const handleToggle = async (userId) => {
      try {
        const authToken = localStorage.getItem("authToken");
    
        if (!authToken) {
          throw new Error("Auth token not found");
        }
    
        const response = await fetch(
          `${config.apiDomain}/api/admin/teacher_active_switch`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify({ teacher_id: userId }),
          }
        );
    
        const responseData = await response.json();
        const { st, msg } = responseData;
    
        if (st === 1) {
          const updatedData = data.map((item) => {
            if (item.user_id === userId) {
              return { ...item, active_status: !item.active_status };
            }
            return item;
          });
    
          setData(updatedData);
          window.showToast("success", msg || "Active status updated successfully!");
          await fetchData();
        } else {
          switch (st) {
            case 2:
              window.showToast("danger", msg || "Error updating active status.");
              break;
            case 3:
              window.showToast("warning", msg || "Warning: Check active status update.");
              break;
            case 4:
              window.showToast("info", msg || "Info: Active status update with additional notes.");
              break;
            default:
              window.showToast("error", "Something went wrong. Contact Support.");
          }
        }
      } catch (error) {
        console.error("Error updating active status:", error);
    
        if (error.response && error.response.status === 401) {
          console.error("Unauthorized access, redirecting to common login screen");
          navigate("/login");
        } else {
          const errorMsg = error.response?.data?.msg || error.message || "Something went wrong. Contact Support.";
          window.showToast("error", errorMsg);
        }
      }
    };
    
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const authToken = localStorage.getItem("authToken");
        const userId = localStorage.getItem("userId");
        if (!authToken || !userId) {
          navigate("/login");
          return;
        }

        const response = await axios.post(
          `${config.apiDomain}/api/admin/manage_teachers/listview`,
          {
            admin_id: userId, // Include admin_id in the request body
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.data && response.data.st === 1) {
          const sortedData = response.data.data.sort(
            (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
          );
          const totalCount = sortedData.length;
          const activeCount = sortedData.filter(
            (item) => item.active_status
          ).length;
          const tradeCount = sortedData.filter(
            (item) => item.trade_status
          ).length;
    
          // Update state for teacher counts
          setTeacherCount(response.data.teacher_count);
    
          // Update other state values as before
          setData(sortedData);
          setActiveStatusCount(activeCount);
          setTradeStatusCount(tradeCount);
          setTotalCount(totalCount);
        } else {
          const errorMsg = response.data.msg || "Failed to fetch data";
          setError(new Error(errorMsg));
        }
      } catch (error) {
        console.error("Error fetching data:", error);

        if (error.response && error.response.status === 401) {
          console.error(
            "Unauthorized access, redirecting to common login screen"
          );
          navigate("/login");
        } else {
          const errorMsg = error.response
            ? error.response.data.msg || "Failed to fetch data"
            : error.message || "Failed to fetch data";
          setError(new Error(errorMsg));
          
        }
      } finally {
        setLoading(false);
      }
    };

    const handleRefresh = async () => {
      setLoading(true);
      setError(null);
      setIsRefreshing(true);
    
      try {
        const authToken = localStorage.getItem("authToken");
        const userId = localStorage.getItem("userId");
        if (!authToken || !userId) {
          navigate("/login");
          return;
        }
    
        const response = await axios.post(
          `${config.apiDomain}/api/admin/manage_teachers/listview`,
          {
            admin_id: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
    
        if (response.data && response.data.st === 1) {
          const sortedData = response.data.data.sort(
            (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
          );
          const totalCount = sortedData.length;
          const activeCount = sortedData.filter(
            (item) => item.active_status
          ).length;
          const tradeCount = sortedData.filter(
            (item) => item.trade_status
          ).length;
    
          // Update state for teacher counts
          setTeacherCount(response.data.teacher_count);
    
          // Update other state values as before
          setData(sortedData);
          setActiveStatusCount(activeCount);
          setTradeStatusCount(tradeCount);
          setTotalCount(totalCount);
    
        
        
    
        } else if (response.data && response.data.st === 2) {
          const errorMsg = response.data.msg || "Warning";
          setError(new Error(errorMsg));
          window.showToast("warning", toTitleCase(errorMsg)); // Use your custom toast for warnings
    
        } else if (response.data && (response.data.st === 3 || response.data.st === 4)) {
          const errorMsg = response.data.msg || "Danger: Server Error";
          setError(new Error(errorMsg));
          window.showToast("error", toTitleCase(errorMsg)); // Use your custom toast for errors
    
        } else {
          const errorMsg = response.data.msg || "Failed to fetch data";
          setError(new Error(errorMsg));
          window.showToast("error", toTitleCase(errorMsg)); // Use custom toast for general errors
        }
      } catch (error) {
        console.error("Error fetching data:", error);
    
        if (error.response && error.response.status === 401) {
          console.error("Unauthorized access, redirecting to common login screen");
          navigate("/login"); 
        } else {
          const errorMsg = error.response
            ? error.response.data.msg || "  Something went wrong. Contact Support"
            : error.message || " Something went wrong. Contact Support";
          setError(new Error(errorMsg));
        window.showToast("error", errorMsg); // Display error using custom toast
        }
      } finally {
        setLoading(false);
        setIsRefreshing(false);
      }
    };
    

    const handleDelete = async () => {
      if (!rowToDelete) return;

      try {
        const authToken = localStorage.getItem("authToken");
        const userId = localStorage.getItem("userId");

        if (!authToken || !userId) {
          navigate("/login");
          return;
        }

        const response = await fetch(
          `${config.apiDomain}/api/admin/manage_teachers/delete`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify({
              teacher_id: rowToDelete.user_id,
              admin_id: userId,
            }),
          }
        );

        const responseData = await response.json();

        if (response.ok && responseData.st === 1) {
          const updatedData = data.filter(
            (item) => item.user_id !== rowToDelete.user_id
          );
          setData(updatedData);
        }
      } catch (error) {
        console.error("Error deleting teacher:", error);

        if (error.response && error.response.status === 401) {
          console.log("Unauthorized access detected. Redirecting to login...");
          navigate("/login");
        }

        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.response?.data?.msg || "Failed to delete teacher",
          life: 2000,
        });
      } finally {
        setShowConfirmModal(false); 
      }
    };

    const confirmDelete = (rowData) => {
      setRowToDelete(rowData); 
      setShowConfirmModal(true); 
    };

    useEffect(() => {
      fetchData();
    }, []);

    const subscriptionBodyTemplate = (rowData) => {
      const subscription = rowData.active_subscription;
    
      if (!subscription) {
        return <span className="text-danger">No Subscription</span>;
      }
    
      return (
        <div className="row ">
          {/* Subscription Name */}
          <div className="col-12 col-md-7 text-secondary text-capitalize fs-md-5">
            <strong>{subscription.subscription_name}</strong>
          </div>
    
          {/* Plan Name Badge */}
          <div className="col-12 col-md-5 mt-2 mt-md-0">
            <span className="badge rounded-pill bg-label-secondary text-capitalize">
              {subscription.plan_name}
            </span>
          </div>
    
          {subscription.remaining_days != null && (
            <>
              <div className="col-12 text-muted mt-2">
                Completed: {subscription.completed_days} Days
              </div>
              <div className="col-12 mt-1">
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: `${
                        (subscription.completed_days / subscription.service_period) * 100
                      }%`,
                    }}
                    aria-valuenow={
                      (subscription.completed_days / subscription.service_period) * 100
                    }
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </>
          )}
        </div>
      );
    };
    
    

    const nameBodyTemplate = (rowData) => {
      return (
        <div className="row">
          <div className=" mt-6 col-12 col-md-2 d-flex justify-content-center">
            <label
              className={`switch switch-square ${
                rowData.active_status ? "bg-success" : ""
              }`}
            >
              <input
                type="checkbox"
                className="switch-input"
                checked={rowData.active_status}
                onChange={() => handleToggle(rowData.user_id)}
              />
              <span
                className={`switch-toggle-slider ${
                  rowData.active_status ? "bg-success" : ""
                }`}
              >
                <span className="switch-on"></span>
                <span className="switch-off"></span>
              </span>
            </label>
          </div>

          <div className="col-12 col-md-10 mt-3 mt-md-0">
            <div className="text-capitalize mb-1 fs-5">
              <strong>{rowData.name}</strong>
            </div>
            <div className="text-muted">{rowData.mobile}</div>
          </div>
        </div>
      );
    };
    const toTitleCase = (str) => {
      return str.replace(/\w\S*/g, (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    };
    const lotSizeBodyTemplate = (rowData) => {
      return `${rowData.lot_size_limit} Lot`;
    };

    const commissionBodyTemplate = (rowData) => {
      return `${rowData.commission}%`;
    };
    const handleBack = () => {
      if (!backClicked) {
        setBackClicked(true);
        navigate(-1);
      }
    };


    const tradeStatusBodyTemplate = (rowData) => {
      return (
        <div className="d-flex justify-content-center align-items-center">
          {rowData.broker_status ? (
            <label
              className={`switch switch-square ${
                rowData.trade_status ? "bg-success" : ""
              }`}
            >
              <input
                type="checkbox"
                className="switch-input"
                checked={rowData.trade_status}
                onChange={() => handleToggleTrade(rowData.user_id)}
              />
              <span
                className={`switch-toggle-slider ${
                  rowData.trade_status ? "bg-success" : ""
                }`}
              >
                <span className="switch-on"></span>
                <span className="switch-off"></span>
              </span>
            </label>
          ) : (
            <span></span>
          )}
        </div>
      );
    };
    
    const brokerNameMap = {
      angle_one: "AngelOne",
      dhan:"Dhan"
    };

    const getBrokerDisplayName = (brokerName) => {
      return brokerNameMap[brokerName] || brokerName; 
    };

    return (
      <>
        <Toast ref={toast} />
        <AdminHeader />
        <AdminSubHeader />

        <div className="container-xxl container-p-y" align="center">
          <nav aria-label="breadcrumb">
            <div className="d-flex justify-content-between align-items-center">
              <ol className="breadcrumb breadcrumb-style1 text-black mb-3">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-black">
                    <i className="ri-home-7-line ri-lg"></i>
                  </Link>
                </li>

                <li
                  className="breadcrumb-item active text-secondary"
                  aria-current="page"
                >
                  Teacher
                </li>
              </ol>
            
              <SubscriptionBadge></SubscriptionBadge>
            </div>
          </nav>
          <div className="row mb-3">
    {teacherCount.total_teacher_count > 0 && (
      <div className="col-6 col-sm-4 col-lg-2 mb-2">
        <div className="card card-border-shadow-primary h-100">
          <div className="card-body">
            <div className="d-flex align-items-center mb-2">
              <div className="avatar me-4">
                <span className="avatar-initial rounded bg-label-primary">
                  <i className="ri-shield-user-line ri-xl"></i>
                </span>
              </div>
              <h4 className="mb-0 fs-3">{teacherCount.total_teacher_count}</h4>
            </div>
            <h6 className="mb-0 fw-normal">Total Teachers</h6>
          </div>
        </div>
      </div>
    )}
    {teacherCount.active_teacher_count > 0 && (
      <div className="col-6 col-sm-4 col-lg-2 mb-2">
        <div className="card card-border-shadow-primary h-100">
          <div className="card-body">
            <div className="d-flex align-items-center mb-2">
              <div className="avatar me-4">
                <span className="avatar-initial rounded bg-label-primary">
                  <i className="ri-shield-user-line ri-xl"></i>
                </span>
              </div>
              <h4 className="mb-0 fs-3">{teacherCount.active_teacher_count}</h4>
            </div>
            <h6 className="mb-0 fw-normal">Active Teachers</h6>
          </div>
        </div>
      </div>
    )}
    {teacherCount.inactive_teacher_count > 0 && (
      <div className="col-6 col-sm-4 col-lg-2 mb-2">
        <div className="card card-border-shadow-primary h-100">
          <div className="card-body">
            <div className="d-flex align-items-center mb-2">
              <div className="avatar me-4">
                <span className="avatar-initial rounded bg-label-primary">
                  <i className="ri-shield-user-line ri-xl"></i>
                </span>
              </div>
              <h4 className="mb-0 fs-3">{teacherCount.inactive_teacher_count}</h4>
            </div>
            <h6 className="mb-0 fw-normal text-truncate">Inactive Teachers</h6>
          </div>
        </div>
      </div>
    )}
  </div>

          <div className="card p-5">
            <div className="row align-items-center mb-5">
              <div className="col-4 text-start">
                <button
                  onClick={handleBack}
                  className="btn rounded-pill btn-outline-secondary btn-xs"
                >
                  <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
                </button>
              </div>
              <div className="col-4 text-center">
                <h5 className="mb-0"> Teacher</h5>
              </div>
              <div className="col-2"></div>
            </div>

            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-flex align-items-center">
                <IconField iconPosition="left">
                  <InputIcon className="ri ri-search-line"></InputIcon>
                  <InputText
                    type="search"
                    placeholder="Search"
                    value={globalFilter}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    className="rounded"
                  />
                </IconField>
                {loading ? (
                <div className="d-flex align-items-center">
                  <i
                    className={`ri-refresh-line ri-lg ms-3 p-text-secondary ${
                      isRefreshing ? "rotate" : ""
                    }`}
                    role="status"
                  ></i>
                </div>
              ) : (
                <div className="">
                  <Tooltip target=".custom-target-icon" />
                  <i
                    className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
                    role="button"
                    data-pr-tooltip="Reload"
                    onClick={handleRefresh}
                    data-pr-position="top"
                  ></i>
                </div>
              )}
              </div>
            </div>

            <DataTable
              value={data}
              rows={20}
              loading={loading}
              globalFilter={globalFilter}
              emptyMessage="No records found"
              className="custom-column-border p-datatable-rounded"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              rowsPerPageOptions={[10, 20, 50]}
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              paginator
              // body={nameBodyTemplate}
            >
              <Column
                align={"center"}
                className="custom-column-border "
                header="No."
                body={(rowData, { rowIndex }) => rowIndex + 1}
              ></Column>
              <Column
                align={"center"}
                className="custom-column-border"
                field="name"
                header={
                  <div>
                    <span>Name</span>
                    <small className="text-muted me-1">
                      {" "}
                      ({activeStatusCount}/{totalCount})
                    </small>
                  </div>
                }
                body={nameBodyTemplate}
              ></Column>

              <Column
                align={"center"}
                className="custom-column-border  "
                field="subscription"
                header="Subscription"
                body={subscriptionBodyTemplate}
              
              ></Column>
              <Column
                align={"center"}
                className="custom-column-border"
                field="lot_size_limit"
                header="Lot Size Limit"
                body={lotSizeBodyTemplate}
              ></Column>
              <Column
                align={"center"}
                className="custom-column-border"
                field="commission"
                header="Commission"
                body={commissionBodyTemplate}
              ></Column>

  <Column
    align={"center"}
    className="custom-column-border w-25"
    header={
      <div>
        <span>Broker</span>
        <small className="text-muted me-1">
          {" "}
          ({tradeStatusCount}/{totalCount})
        </small>
      </div>
    }
    body={(rowData) => (
      <div
        className={classNames({
          "text-success": rowData.broker_status,
          "text-danger": !rowData.broker_status,
        })}
      >
        {rowData.broker_status ? (
          <div className="d-flex flex-wrap justify-content-center align-items-center">
            {/* Display toggle in a new row on mobile */}
            <div className="col-12 col-md-12 col-lg-3 d-flex justify-content-center align-items-end mb-3 mb-md-0">
              {tradeStatusBodyTemplate(rowData)}
            </div>

            {/* Ensure logo and broker name are in the same line */}
            <div className="col-12 col-md-6 d-flex flex-column align-items-center">
              <div className="d-flex align-items-center flex-nowrap">
                {rowData.broker_name === "angle_one" && (
                  <img
                    src={dhanlogo} // Assuming correct path for Angel One
                    alt="Angel One"
                    style={{ height: "20px" }}
                    className="me-2"
                  />
                )}
                {rowData.broker_name === "dhan" && (
                  <img
                    src={dhanlogo}
                    alt="Dhan"
                    style={{ height: "20px" }}
                    className="me-2"
                  />
                )}

                {/* Broker name next to logo */}
                <span className={rowData.trade_status ? "text-success" : "text-muted"}>
                  {getBrokerDisplayName(rowData.broker_name)}
                </span>
              </div>

              {/* Display the broker account balance in a new line */}
              <div className="text-muted text-center mt-1">
                {rowData.broker_account_balance || 0} Rs
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <span>Broker Not Connected</span>
          </div>
        )}
      </div>
    )}
  />




              <Column
                align={"center"}
                className="custom-column-border"
                header="Actions"
                body={(rowData) => (
                  <>
                    <Link
                      to={`/admin/manage_teacher/view_teacher/${rowData.user_id}`}
                      state={{ teacherId: rowData.user_id }}
                    >
                      <button
                        className="btn btn-info me-3 custom-btn-action1 custom-target-icon"
                        data-pr-tooltip="View Record"
                        data-pr-position="top"
                      >
                        <i className="ri-gallery-view-2 ri-lg"></i>
                      </button>
                    </Link>

                    {/* <button
                      className="btn btn-danger active text-align custom-btn-action1 custom-target-icon"
                      data-pr-tooltip="Delete Record"
                      data-pr-position="top"
                      onClick={() => confirmDelete(rowData)}
                    >
                      <i className="ri-delete-bin-line ri-lg"></i>
                    </button> */}
                  </>
                )}
              ></Column>
            </DataTable>
          </div>
          <CardLinks></CardLinks>
        </div>

        <Footer />
        <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure?</Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-between w-100 ms-0 me-0">
              <button
                onClick={() => setShowConfirmModal(false)}
                className="btn btn-outline-secondary rounded-pill btn-sm"
              >
                <i className="ri-close-large-line me-2"></i>
                <span>Cancel</span>
              </button>
              <button
                onClick={handleDelete}
                className="btn btn-danger rounded-pill btn-sm"
              >
                <i className="ri-delete-bin-line me-2"></i>
                Delete
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  export default ManageTeacher;
