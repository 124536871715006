import SubHeader from "../component/SubHeader";
import Header from "../component/Header";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Toast } from "primereact/toast";
import { Modal, Button } from "react-bootstrap";
import config from "../../../component/config";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import Footer from "../../../component/Footer";
import CardLinks from "../../../component/CardLinks";
import SubscriptionBadge from "../../../component/SubscriptionBadge";
import dhanLogo from "../../../assets/logo/dhanlogo.jpeg";
import angeloneLogo from "../../../assets/logo/angelonelogo.png";
import Skeleton from "react-loading-skeleton";
const ActivityLog = () => {
  const [loading, setLoading] = useState(false);
  const toast = React.useRef(null);
  const [loadings, setLoadings] = useState(false);
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [activityList, setActivityList] = useState([]);
  const userId = localStorage.getItem("userId");
  const [globalFilter, setGlobalFilter] = useState("");
  const [isRefreshing, setIsRefreshing] = useState(true);
  const [selectedBroker, setSelectedBroker] = useState(null);
  const handleSearch = (e) => {
    setGlobalFilter(e.target.value);
  };
  const [brokerData, setBrokerData] = useState({});

 
  const [formData, setFormData] = useState(userData);
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const authToken = localStorage.getItem("authToken");
        const userId = localStorage.getItem("userId");

        if (!authToken || !userId) {
          navigate("/login");
          return;
        }

        const response = await axios.post(
          `${config.apiDomain}/api/common/get_profile_details`,
          {
            user_id: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.data.st === 1) {
          setUserData(response.data.user_details);
          setBrokerData(response.data.broker_details);
          setFormData({
            ...response.data.user_details,
            ...response.data.broker_details,
          });
          setSelectedBroker(
            response.data.broker_details?.broker || "angle_one"
          );
        } else {
        }
      } catch (error) {
        let errorMsg = "Failed to fetch data";
        if (error.response) {
          errorMsg = error.response.data.msg
            ? formatMessage(error.response.data.msg)
            : "An error occurred";
        } else if (error.message) {
          errorMsg = error.message;
        }

        if (error.response && error.response.status === 401) {
          navigate("/login");
        }
        console.clear();
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 30)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success";
    } else if (hours === 15) {
      return "danger";
    }
    return "secondary";
  };

  const formatMessage = (msg) => {
    if (typeof msg === "object") {
      return Object.values(msg).flat().join(", ");
    }
    return String(msg);
  };

  const handleConnectionStatus = (status) => {};

  useEffect(() => {
    const fetchActivityLog = async () => {
      const authToken = localStorage.getItem("authToken");
      setLoadings(true);
      try {
        const response = await axios.post(
          "https://ghanish.in/api/common/activity_log",
          {
            user_id: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.data.st === 1) {
          setActivityList(response.data.activity_list);
        } else {
        }
      } catch (error) {
      } finally {
        setLoadings(false);
      }
    };

    fetchActivityLog();
  }, []);
  const formatExchanges = (exchanges) => {
    if (!exchanges) return [];
    const formattedExchanges = [];
    for (const [key, value] of Object.entries(exchanges)) {
      if (Array.isArray(value)) {
        formattedExchanges.push(`${key}: ${value.join(", ")}`);
      } else {
        formattedExchanges.push(`${key}: ${value}`);
      }
    }
    return formattedExchanges;
  };

  const formattedExchanges =
    userData && userData.broker_account_exchanges
      ? formatExchanges(userData.broker_account_exchanges)
      : [];

      const handleRefresh = async () => {
        setLoadings(true);
        const authToken = localStorage.getItem("authToken");
      
        try {
          const response = await axios.post(
            "https://ghanish.in/api/common/activity_log",
            {
              user_id: userId,
            },
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );
      
          // Checking the status `st` and displaying the appropriate toast
          if (response.data.st === 1) {
            setActivityList(response.data.activity_list);
         
          } else if (response.data.st === 2) {
            window.showToast("danger", response.data.msg || "Operation failed!");
          } else if (response.data.st === 3) {
            window.showToast("warning", response.data.msg || "Warning! Please check.");
          } else if (response.data.st === 4) {
            window.showToast("info", response.data.msg || "Information message.");
          } else {
            window.showToast(
              "danger",
              "Something went wrong. Contact Support."
            );
          }
        } catch (error) {
          // Fallback for any server error
          window.showToast(
            "danger",
            "Something went wrong. Contact Support."
          );
        } finally {
          setLoadings(false);
        }
      };

      const brokerNameMap = {
        angle_one: "AngelOne",
        dhan: "Dhan",
      };
    
      const getBrokerDisplayName = (brokerName) => {
        return brokerNameMap[brokerName] || brokerName;
      };
  return (
    <div>
      <Header />
      <SubHeader />
      <Toast ref={toast} />

      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="layout-navbar-full layout-horizontal layout-without-menu">
        <div className="layout-page">
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <nav aria-label="breadcrumb">
                <div className="d-flex justify-content-between align-items-center">
                  <ol className="breadcrumb breadcrumb-style1 text-black">
                    <li className="breadcrumb-item">
                      <Link to="/teacher/dashboard" className="text-black">
                        <i className="ri-home-7-line ri-lg"></i>
                      </Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-secondary"
                      aria-current="page"
                    >
                      Profile
                    </li>
                  </ol>
                  <SubscriptionBadge></SubscriptionBadge>
                </div>
              </nav>

              <div className="row ">
                <div className="col-md-4">
                  <div className="card ">
                  <div className="card-body pt-0">
   
   <div className="w-px-40 h-auto mx-auto mt-3 rounded-circle">
   {loading ? (
<Skeleton circle={true} height={80} width={80} />
) : (
     <div className="d-flex justify-content-center align-items-center border border-primary border-3 rounded-circle bg-label-primary avatar-xl">
       <span className="avatar-initial rounded-circle bg-label-primary">
         {userData.name &&
           userData.name
             .split(" ")
             .filter((word, index) => index === 0 || index === userData.name.split(" ").length - 1)
             .map((word) => word.charAt(0).toUpperCase())
             .join("")}
       </span>
     </div>
)}
   </div>
   {loading ? (
     <Skeleton count={1} height={30} className="mt-3" />
   ) : (
     <>
   <div className="user-info mt-3 text-center">

     <h5 className="text-capitalize">{userData.name}</h5>
     <span className="btn btn-outline-secondary rounded-pill btn-xs">
       {userData.role}
     </span>
   </div>
   </>)}

   <ul className="list-unstyled my-3 py-1">
   {loading ? (
<Skeleton count={1} height={30} className="" />
) : (
<>
   {brokerData && (
     <li className="d-flex justify-content-between align-items-center mb-4">
       <strong>Broker</strong>
       <span className="ml-auto d-flex align-items-center">
         {selectedBroker === "angle_one" && (
           <img src={angeloneLogo} alt="AngleOne" style={{ height: "18px" }} className="me-1 border border-1 rounded-pill" />
         )}
         {selectedBroker === "dhan" && (
           <img src={dhanLogo} alt="Dhan" style={{ height: "18px" }} className="me-1 border border-1 rounded-pill" />
         )}
         {getBrokerDisplayName(selectedBroker)}
       </span>
     </li>
   )}
   {brokerData && (
      <li className="d-flex justify-content-between align-items-center mb-4">
       <strong>Broker Acc. Balance:</strong>
       <span className="ml-auto">
     {`${userData.broker_account_fund} Rs.`}
       </span>
     </li>
   )}
  
     <li className="d-flex justify-content-between align-items-center mb-4">
       <strong>Commission:</strong>
       <span className="ml-auto">{`${userData.commission}%`}</span>
     </li>
     <li className="d-flex justify-content-between align-items-center mb-4">
       <strong>Lot Size Limit:</strong>
       <span className="ml-auto">{`${userData.lot_size_limit} Lot`}</span>
     </li>
     <li className="d-flex justify-content-between align-items-center">
  <strong>Trade Status</strong>
  <span className="ml-auto">
    {userData.trade_status ? "Active" : "Inactive"}
  </span>
</li>

     {formattedExchanges.length > 0 && (
                       <li className="mt-4">
                         <strong>Segments:</strong>
                         <span className="ml-auto">
                           <ul className="list-unstyled text-end">
                             {" "}
                             {/* New <ul> for the exchanges */}
                             {formattedExchanges.map((exchange, index) => (
                               <li key={index}>{exchange}</li>
                             ))}
                           </ul>
                         </span>
                       </li>
                     )}
                     </>)}
   </ul>

   <hr />
   <ul className="list-unstyled my-3">
{loading ? (
<Skeleton count={1} height={30} className="" />
) : (
<>
{userData.email && (
  <li className="d-flex justify-content-between align-items-center mb-4">
    <strong>Email:</strong>
    <span className="ml-auto">{userData.email}</span>
  </li>
)}
 <li className="d-flex justify-content-between align-items-center">
   <strong>Mobile:</strong>
   <span className="ml-auto">{userData.mobile}</span>
 </li>
</>
)}
</ul>

</div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="row mt-4">
                    <div className="col-md-8 col-sm-7">
                      <div className="nav-align-top">
                      <ul className="nav flex-row flex-wrap mb-3 justify-content-start justify-content-md-start justify-content-center">
                          <li className="nav-item active">
                            <Link
                              to="/teacher/user_profile"
                              className="nav-link"
                            >
                              <i className="ri-user-3-line me-1_5"></i>Profile
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link
                              to="/teacher/user_profile_Timeline"
                              className="nav-link "
                            >
                              <i className="ri-timeline-view me-1_5"></i>
                              Timeline
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="/teacher/user_profile_login_log"
                              className="nav-link  "
                            >
                              <i className="ri-news-line me-1_5"></i>Login log
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="/teacher/user_profile_activity_log"
                              className="nav-link active btn btn-primary"
                            >
                              <i className="ri-file-list-2-line me-1_5"></i>
                              Activity log
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="container-xxl p-md-0 p-lg-0">
                      <Toast ref={toast} />
                      <div className="card p-6">
                       
                          <div className="row mb-0 mt-0">
                            <div className="col-12 text-center">
                              <h5>Recent Activities</h5>
                            </div>
                         

                          <div className="row">
                            <div className="col-12 mb-0 d-flex justify-content-start align-items-center">
                              <div className="d-flex align-items-center">
                                <IconField iconPosition="left">
                                  <InputIcon className="ri ri-search-line"></InputIcon>
                                  <InputText
                                    type="search"
                                    value={globalFilter}
                                    onChange={handleSearch}
                                    placeholder="Search..."
                                  />
                                </IconField>
                              </div>
                              <div className="d-flex align-items-center ms-3">
                              {loadings ? (
                                <i
                                  className={`ri-refresh-line ri-lg p-text-secondary ${
                                    isRefreshing ? "rotate" : ""
                                  }`}
                                  role="status"
                                ></i>
                              ) : (
                                <div className="mt-1">
                                  <Tooltip target=".custom-target-icon" />
                                  <i
                                    className="custom-target-icon ri ri-refresh-line ri-lg p-text-secondary"
                                    role="button"
                                    data-pr-tooltip="Reload"
                                    onClick={handleRefresh}
                                    data-pr-position="top"
                                  ></i>
                                </div>
                              )}
                            </div>
                            </div>
                          </div>
                        </div>
                        {loading ? (
                       // Skeleton for the DataTable
                       <div className="mt-4">
                         <Skeleton height={40} count={5} />
                       </div>
                     ) : (
                        <DataTable
                          value={activityList}
                          paginator
                          rows={5}
                          showGridlines
                          globalFilter={globalFilter}
                          loading={loadings}
                          emptyMessage="No activities found."
                          className="custom-column-border mt-4 "
                        >
                          <Column
                            field="description"
                            header="Details"
                            className="text-truncate custom-column-border"
                          />
                          <Column
                            field="datetime"
                            header="Recent Activities"
                            className="text-truncate custom-column-border"
                          />
                        </DataTable>
                     )}
                      </div>
                    </div>
                </div>
                <CardLinks></CardLinks>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityLog;
const getColorModalClass = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9 || hours === 15) {
    return hours === 9 ? "modal-green" : "modal-red";
  }
  return "";
};

const getModalTitle = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is Open!";
  } else if (hours === 15) {
    return "Market is Closed!";
  }
  return "";
};

const getModalBody = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is currently open. Take necessary actions.";
  } else if (hours === 15) {
    return "Market is currently closed. Come back tomorrow.";
  }
  return "";
};
