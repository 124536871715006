import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import dhanlogo from "../assets/logo/dhanlogo.jpeg";
import angeloneLogo from "../assets/logo/angelonelogo.png";

const SubscriptionBadge = () => {
  const [userSubscriptionName, setUserSubscriptionName] = useState(null);
  const [userPlanName, setUserPlanName] = useState(null);
  const [brokerConnectionStatus, setBrokerConnectionStatus] = useState(null);
  const [broker, setBroker] = useState('');  // Default empty string instead of null
  const [broadcastingFlag, setBroadcastingFlag] = useState(false); 
  const userId = localStorage.getItem("userId");
  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    const status = JSON.parse(localStorage.getItem("brokerConnectionStatus"));
    setBrokerConnectionStatus(status);

    const fetchSubscription = async () => {
      try {
        const response = await axios.post(
          'https://ghanish.in/api/teacher/get_active_subscription',
          { teacher_id: userId },
          {
            headers: {
              Authorization: `Bearer ${authToken}`
            }
          }
        );

        if (response.data && response.data.st === 1) {
          const { subscription_name, plan_name, broadcasting_flag } = response.data.active_subscription;
          const broker = response.data.broker; // Extract broker directly from the root

          if (broker) {
            setBroker(broker);
          } else {
          }

          localStorage.setItem("broadcastingFlag", JSON.stringify(broadcasting_flag));

          setBroadcastingFlag(broadcasting_flag); 
          setUserSubscriptionName(subscription_name);
          setUserPlanName(plan_name);
        }
      } catch (error) {
        console.clear();
      }
    };

    fetchSubscription();
  }, [userId, authToken]);

  useEffect(() => {
  }, [broker]);

  return (
    <div className="mb-3 d-md-flex flex-wrap justify-content-end">
      {userSubscriptionName && userPlanName ? (
        <div className="mb-2">
          <span className="btn btn-outline-secondary d-none d-md-flex rounded-pill btn-xs text-capitalize me-2">
            {userSubscriptionName.trim()} : {userPlanName.trim()}
          </span>
        </div>
      ) : null}

      {brokerConnectionStatus && (
        <div className="mb-2">
          <span className="btn btn-outline-secondary rounded-pill btn-xs me-2">
            Broker  
            {broker === "angle_one" ? (
              <img
                src={angeloneLogo}
                alt="Angel One"
                style={{ height: "18px" }}
                className="ms-1 border border-1 rounded-pill"
              />
            ) : broker === "dhan" ? (
              <img
                src={dhanlogo}
                alt="Dhan"
                style={{ height: "18px" }}
                className="ms-1 border border-1 rounded-pill"
              />
            ) : (
              <span>Unknown Broker</span> // Fallback in case of an unknown broker
            )}
          </span>
        </div>
      )}
    </div>
  );
};

export default SubscriptionBadge;
