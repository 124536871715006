import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import Header from "../component/Header";
import Footer from "../../../component/Footer";
import SubHeader from "../component/SubHeader";
import { Toast } from "primereact/toast";
import axios from "axios";
import config from "../../../component/config";
import { Modal, Button } from "react-bootstrap";
import CardLinks from "../../../component/CardLinks";
import SubscriptionBadge from "../../../component/SubscriptionBadge";
import Skeleton from "react-loading-skeleton";
const UpdateStudent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const studentData = location.state || {};
  const { id } = useParams();
  const [teacherData, setTeacherData] = useState({});
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [backClicked, setBackClicked] = useState(false);
  const [withBrokerDetails, setWithBrokerDetails] = useState(false);
  const [selectedBroker, setSelectedBroker] = useState("dhan");
  const [validationErrors, setValidationErrors] = useState({});
  const toast = useRef(null);
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const authToken = localStorage.getItem("authToken");
        const userId = localStorage.getItem("userId");

        if (!authToken || !userId) {
          navigate("/login");
          return;
        }

        const response = await axios.post(
          `${config.apiDomain}/api/teacher/manage_students/view`,
          {
            student_id: id,
            teacher_id: userId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
            },
          }
        );

        if (response.data && response.data.st === 1) {
          const studentInfo = response.data.data;
          setSelectedBroker(studentInfo.broker);
          setTeacherData({
            ...studentInfo,
            broker_details: studentInfo.broker_details || {},
          });
          setWithBrokerDetails(studentInfo.broker_status); 
        } else {
          setError(new Error(response.data.msg || "Failed to fetch data"));
        }
      } catch (error) {
        console.clear(); 
        if (error.response && error.response.status === 401) {
         
          navigate("/login"); 
        } else {
          const errorMsg = error.response
            ? error.response.data.msg || "Failed to fetch data"
            : error.message || "Failed to fetch data";
          setError(new Error(errorMsg));
        
        }
      } finally {
        setLoading(false);
      }
    };
    if (id) {
      fetchData();
    }
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!validate()) return;
  
    const requestData = {
      student_id: id,
      teacher_id: userId,
      name: teacherData.name,
      email: teacherData.email,
      mobile: teacherData.mobile,
      commission: teacherData.commission,
      lot_size_limit: teacherData.lot_size_limit,
      broker: selectedBroker, 
    };
  
    if (withBrokerDetails) {
      if (selectedBroker === "angle_one") {
        requestData.broker_details = {
          api_key: teacherData.broker_details.api_key,
          client_id: teacherData.broker_details.client_id,
          password: teacherData.broker_details.password,
          qr_totp_token: teacherData.broker_details.qr_totp_token,
        };
      } else if (selectedBroker === "dhan") {
        requestData.broker_details = {
          dhan_client_id: teacherData.broker_details.dhan_client_id,
          access_token: teacherData.broker_details.access_token,
        };
      }
    }
  
    setIsLoading(true);
  
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) throw new Error("Auth token not found");
  
      const url = `${config.apiDomain}/api/teacher/manage_students/update?with_broker_details=${withBrokerDetails ? "True" : "False"}`;
  
      const response = await axios.put(url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });
  
      if (response.data.st === 1) {
        window.showToast("success", response.data.msg || "Update successful");
        setTimeout(() => {
          navigate("/teacher/manage_student");
        }, 1000);
      } else {
        // Handle different `st` values for errors and warnings
        switch (response.data.st) {
          case 2:
            window.showToast("danger", response.data.msg || "Error: Update failed.");
            break;
          case 3:
            window.showToast("warning", response.data.msg || "Warning: Please check the update details.");
            break;
          case 4:
            window.showToast("info", response.data.msg || "Info: Update completed with notes.");
            break;
          default:
            window.showToast("error", "Something went wrong. Contact Support.");
        }
      }
    } catch (error) {
      if (error.response?.status === 401) {
        navigate("/login");
      } else {
        const errorMsg = error.response?.data?.msg || error.message || "Something went wrong. Contact Support.";
        window.showToast("error", errorMsg);
      }
    } finally {
      setIsLoading(false);
    }
  }          

  const formatMessage = (msg) => {
    if (typeof msg === "object" && msg !== null) {
      return Object.values(msg).flat().join(", ");
    }
    return String(msg);
  };

  const validate = () => {
    let errors = {};
    let isValid = true;
  
    // Personal information validations
    if (!teacherData.name) {
      errors.name = "Name is required";
      isValid = false;
    } else if (/\d/.test(teacherData.name)) {
      errors.name = "Name cannot contain digits";
      isValid = false;
    } else if (/[^a-zA-Z\s]/.test(teacherData.name)) {
      errors.name = "Name cannot contain special characters";
      isValid = false;
    } else if (teacherData.name.length < 3) {
      errors.name = "Name must be at least 3 characters";
      isValid = false;
    } else if (teacherData.name.length > 50) {
      errors.name = "Name must be at most 50 characters";
      isValid = false;
    }
  
    if (!teacherData.email) {
      errors.email = "Email is required";
      isValid = false;
    } else {
      const emailPattern = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const localPart = teacherData.email.split("@")[0];
  
      if (!emailPattern.test(teacherData.email)) {
        errors.email = "Email is invalid";
        isValid = false;
      } else if (/[^a-zA-Z0-9._+-]/.test(localPart)) {
        errors.email =
          "Email can only contain special characters (., -, _, +) in the local part";
        isValid = false;
      }
    }
  
    if (!teacherData.mobile) {
      errors.mobile = "Mobile number is required";
      isValid = false;
    } else if (!/^\d+$/.test(teacherData.mobile)) {
      errors.mobile = "Mobile number must contain only digits";
      isValid = false;
    } else if (teacherData.mobile.length !== 10) {
      errors.mobile = "Mobile number must be exactly 10 digits";
      isValid = false;
    }
  
    if (teacherData.lot_size_limit === "") {
      errors.lot_size_limit = "Lot size limit is required";
      isValid = false;
    } else if (isNaN(teacherData.lot_size_limit)) {
      errors.lot_size_limit = "Lot size limit must be a number";
      isValid = false;
    } else {
      const lotSizeLimit = parseInt(teacherData.lot_size_limit, 10);
      if (lotSizeLimit < 1 || lotSizeLimit > 5000) {
        errors.lot_size_limit = "Lot size limit must be between 1 and 5000";
        isValid = false;
      }
    }
  
    if (!teacherData.commission) {
      errors.commission = "Commission is required";
      isValid = false;
    } else if (!/^[0-9%]+$/.test(teacherData.commission)) {
      errors.commission =
        "Commission must only contain digits and the '%' character";
      isValid = false;
    } else if (
      teacherData.commission.includes("%") &&
      teacherData.commission.length === 1
    ) {
      errors.commission = "Commission cannot be just '%'";
      isValid = false;
    }
  
    // Broker information validations
    if (withBrokerDetails) {
      if (selectedBroker === "angle_one") {
        if (!teacherData.broker_details.api_key) {
          errors.api_key = "API Key is required for AngleOne";
          isValid = false;
        }
        if (!teacherData.broker_details.client_id) {
          errors.client_id = "Client ID is required for AngleOne";
          isValid = false;
        }
        if (!teacherData.broker_details.password) {
          errors.password = "Password is required for AngleOne";
          isValid = false;
        }
        if (!teacherData.broker_details.qr_totp_token) {
          errors.qr_totp_token = "QR TOTP Token is required for AngleOne";
          isValid = false;
        }
      } else if (selectedBroker === "dhan") {
        if (!teacherData.broker_details.dhan_client_id) {
          errors.dhan_client_id = "Client ID is required for Dhan";
          isValid = false;
        }
        if (!teacherData.broker_details.access_token) {
          errors.access_token = "Access Token is required for Dhan";
          isValid = false;
        }
      }
    }
  
    setValidationErrors(errors);
    return isValid;
  };
  

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const handleChange = (field) => (e) => {
    setTeacherData((prevData) => {
      // Check if the field belongs to personal details or broker details
      if (["name", "mobile", "email", "commission", "lot_size_limit"].includes(field)) {
        return {
          ...prevData,
          [field]: e.target.value, // Update personal detail field
        };
      } else {
        return {
          ...prevData,
          broker_details: {
            ...prevData.broker_details,
            [field]: e.target.value, // Update broker detail field
          },
        };
      }
    });
  };
  

  const [showPopup, setShowPopup] = useState(false); 

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

     
      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 30)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000); 

    
    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success";
    } else if (hours === 15) {
      return "danger";
    }
    return "secondary";
  };
  const handleBrokerChange = (broker) => {
    setSelectedBroker(broker);
  };
  return (
    <div>
      <Header />
      <SubHeader />
      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
        <div className="layout-container">
          <div className="Container-xxl">
            <div className="container-xxl flex-grow-1 container-p-y">
              <nav aria-label="breadcrumb">
              <div className="d-flex justify-content-between align-items-center">
                <ol className="breadcrumb breadcrumb-style1">
                  <li className="breadcrumb-item">
                    <Link to="/teacher/dashboard" className="text-black">
                      <i className="ri-home-7-line ri-lg"></i>
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/teacher/manage_student" className="text-black">
                       Student
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active text-secondary"
                    aria-current="page"
                  >
                    Update Student
                  </li>
                </ol>
                <SubscriptionBadge></SubscriptionBadge>
                </div>
              </nav>

              
                <div className="row">
                  <div className="col-md-12">
                    <div className="card mb-6">
                      <div className="d-flex justify-content-between align-items-center card-header position-relative">
                        <button
                          onClick={handleBack}
                          className="btn rounded-pill btn-outline-secondary btn-xs position-absolute start-0 ms-3"
                        >
                          <i className="ri-arrow-left-double-line me-1 ri-md"></i>
                          Back
                        </button>
                        <h5 className="text-center mb-0 w-100">
                          Update Student
                        </h5>
                      </div>
                      <div className="card-body pt-0">
                      {loading ? (
        <Skeleton height={400} /> // Adjust the height as needed
      ) : (
        <>
                        <form id="formAccountSettings" onSubmit={handleSubmit}>
                          <div className="row mt-1 g-3">
                            <h5 className="text-start">
                              {" "}
                              <i className="ri-user-line ri-ms me-2"></i>
                              Personal Information
                            </h5>
                            <div className="col-md-3">
                              <div className="form-floating form-floating-outline">
                                <input
                                  className={`form-control ${
                                    validationErrors.name ? "is-invalid" : ""
                                  }`}
                                  type="text"
                                  id="name"
                                  name="name"
                                  placeholder="Name"
                                  value={teacherData.name}
                                  onChange={handleChange("name")}
                                />
                                <label htmlFor="name">
                                  <span className="text-danger">*</span> Name
                                </label>
                                {validationErrors.name && (
                                  <div className="invalid-feedback">
                                    {validationErrors.name}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="input-group input-group-merge">
                                <div className="form-floating form-floating-outline">
                                  <input
                                    type="text"
                                    id="mobileNumber"
                                    name="mobileNumber"
                                    className={`form-control ${
                                      validationErrors.mobile
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    placeholder="202 555 0111"
                                    value={teacherData.mobile}
                                    onChange={handleChange("mobile")}
                                    maxLength="10"
                                  />
                                  <label htmlFor="mobileNumber">
                                    <span className="text-danger">*</span>{" "}
                                    Mobile Number
                                  </label>
                                  {validationErrors.mobile && (
                                    <div className="invalid-feedback">
                                      {validationErrors.mobile}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-floating form-floating-outline">
                                <input
                                  className={`form-control ${
                                    validationErrors.email ? "is-invalid" : ""
                                  }`}
                                  type="email"
                                  id="email"
                                  name="email"
                                  placeholder="john.doe@example.com"
                                  value={teacherData.email}
                                  onChange={handleChange("email")}
                                />
                                <label htmlFor="email">
                                  <span className="text-danger">*</span> E-mail
                                </label>
                                {validationErrors.email && (
                                  <div className="invalid-feedback">
                                    {validationErrors.email}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-floating form-floating-outline">
                                <input
                                  type="number"
                                  maxLength={5000}
                                  className={`form-control ${
                                    validationErrors.lot_size_limit
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  id="lot_size_limit"
                                  name="lot_size_limit"
                                  placeholder="Lot Size Limit"
                                  value={teacherData.lot_size_limit}
                                  onChange={handleChange("lot_size_limit")}
                                  
                                />
                                <label htmlFor="lot_size_limit">
                                  <span className="text-danger">*</span> Lot
                                  Size Limit
                                </label>
                                {validationErrors.lot_size_limit && (
                                  <div className="invalid-feedback">
                                    {validationErrors.lot_size_limit}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3 g-3">
                            <div className="col-md-3">
                              <div className="form-floating form-floating-outline">
                                <input
                                  type="number"
                                  className={`form-control ${
                                    validationErrors.commission
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  id="commission"
                                  name="commission"
                                  placeholder="Commission"
                                  value={teacherData.commission}
                                  onChange={handleChange("commission")}
                                />
                                <label htmlFor="commission">
                                  <span className="text-danger">*</span>{" "}
                                  Commission
                                </label>
                                {validationErrors.commission && (
                                  <div className="invalid-feedback">
                                    {validationErrors.commission}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-12 mt-3 d-flex justify-content-end">
                            <div className="form-check text-end">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="withBrokerDetails"
                                name="withBrokerDetails"
                                checked={withBrokerDetails}
                                onChange={() =>
                                  setWithBrokerDetails(!withBrokerDetails)
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="withBrokerDetails"
                              >
                                Include Broker Details
                              </label>
                            </div>
                          </div>
                          <hr />
                          {teacherData.is_position_open ? (
  <div
    className="p-3 mt-3"
    style={{
      backgroundColor: "#f9f1e4",
      border: "1px solid #ffc107",
      borderRadius: "4px",
    }}
  >
    <div className="d-flex justify-content-between align-items-start">
      <h5 className="text-start mb-0">
        <i className="ri-alert-line me-2"></i>
        Position is Open, Can Not Update Broker Details.
      </h5>
    </div>
    <p className="text-start mt-3">
      There is an open position. Please close the position before updating broker details.
    </p>
    <div className="text-end">
      <Link to="/trade_position" className="btn btn-primary rounded-pill">
        Go to Position Page
      </Link>
    </div>
  </div>
) : (
<>
                          {withBrokerDetails && teacherData.broker_details && (
                            <div className="row mt-3 g-3">
                              <h5 className="text-start">
                                {" "}
                                <i className="ri-group-line ri-ms me-2"></i>
                                Broker Information
                              </h5>
                              <ul className="nav nav-tabs mb-5" role="tablist">
                                  <li className="nav-item me-5">
                                    <div
                                      className={` ${
                                        selectedBroker === "angle_one"
                                          ? "border-bottom  border-primary text-primary pb-2 border-4"
                                          : "text-black"
                                      }`}
                                      onClick={() =>
                                        handleBrokerChange("angle_one")
                                      }
                                    >
                                      AngleOne
                                    </div>
                                  </li>

                                  <li className="nav-item">
                                    <div
                                      className={` ${
                                        selectedBroker === "dhan"
                                          ? "border-bottom border-primary text-primary pb-2 border-4"
                                          : "text-black"
                                      }`}
                                      onClick={() => handleBrokerChange("dhan")}
                                    >
                                      Dhan
                                    </div>
                                  </li>
                                </ul>
                                {selectedBroker === "angle_one" && (
                                  <>
                              <div className="col-md-3">
                                <div className="form-floating form-floating-outline">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      validationErrors.client_id
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    id="client_id"
                                    name="client_id"
                                    placeholder="Broker Client ID"
                                    value={teacherData.broker_details.client_id}
                                    onChange={handleChange("client_id")}
                                  />
                                  <label htmlFor="client_id">
                                    <span className="text-danger">*</span>{" "}
                                    Broker Client ID
                                  </label>
                                  {validationErrors.client_id && (
  <div className="invalid-feedback">{validationErrors.client_id}</div>
)}
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="form-floating form-floating-outline">
                                  <input
                                    type="password"
                                    className={`form-control ${
                                      validationErrors.password
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    id="password"
                                    name="password"
                                    placeholder="Broker Password"
                                    value={teacherData.broker_details.password}
                                    onChange={handleChange("password")}
                                  />
                                  <label htmlFor="password">
                                    <span className="text-danger">*</span>{" "}
                                    Broker Password
                                  </label>
                                  {validationErrors.password && (
  <div className="invalid-feedback">{validationErrors.password}</div>
)}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating form-floating-outline">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      validationErrors.qr_totp_token
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    id="qr_totp_token"
                                    name="qr_totp_token"
                                    placeholder="Broker QR Totp Token"
                                    value={
                                      teacherData.broker_details.qr_totp_token
                                    }
                                    onChange={handleChange("qr_totp_token")}
                                  />
                                  <label htmlFor="qr_totp_token">
                                    <span className="text-danger">*</span>{" "}
                                    Broker QR TOTP Token
                                  </label>
                                  {validationErrors.qr_totp_token && (
  <div className="invalid-feedback">{validationErrors.qr_totp_token}</div>
)}
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-floating form-floating-outline">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      validationErrors.api_key
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    id="api_key"
                                    name="api_key"
                                    placeholder="Broker API Key"
                                    value={teacherData.broker_details.api_key}
                                    onChange={handleChange("api_key")}
                                  />
                                  <label htmlFor="api_key">
                                    <span className="text-danger">*</span>{" "}
                                    Broker API Key
                                  </label>
                                  {validationErrors.api_key && (
  <div className="invalid-feedback">{validationErrors.api_key}</div>
)}
                                </div>
                              </div>
                              </>   )}

                              {selectedBroker === "dhan" && (
                                <>
                                 <div className="col-md-3">
                                <div className="form-floating form-floating-outline">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      validationErrors.dhan_client_id
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    id="dhan_client_id"
                                    name="dhan_client_id"
                                    placeholder="Broker Client ID"
                                    value={teacherData.broker_details.dhan_client_id}
                                    onChange={handleChange("dhan_client_id")}
                                  />
                                  <label htmlFor="dhan_client_id">
                                    <span className="text-danger">*</span>{" "}
                                    Broker Client ID
                                  </label>
                                  {validationErrors.dhan_client_id && (
  <div className="invalid-feedback">{validationErrors.dhan_client_id}</div>
)}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-floating form-floating-outline">
                                  <textarea
                                            
                                            className={`form-control h-px-75 ${
                                              validationErrors.access_token
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                    id="access_token"
                                    name="access_token"
                                    placeholder="Broker Access Token"
                                    value={teacherData.broker_details.access_token}
                                    onChange={handleChange("access_token")}
                                  />
                                  <label htmlFor="access_token">
                                    <span className="text-danger">*</span>{" "}
                                    Broker Access Token
                                  </label>
                                  {validationErrors.access_token && (
  <div className="invalid-feedback">{validationErrors.access_token}</div>
)}
                                </div>
                              </div>

                                </>)}
                            </div>
                          )}
</>
)}
                          <Toast ref={toast} />
                          <div className="row">
                            <div className="col text-end mt-4">
                              <button
                                type="submit"
                                className="btn rounded-pill btn-info btn-sm"
                                disabled={isLoading}
                              >
                                {isLoading ? (
                                  <div
                                    className="spinner-border me-2"
                                    role="status"
                                  >
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                ) : (
                                  <i className="ri-checkbox-circle-line ri-lg me-1"></i>
                                )}{" "}
                                {isLoading ? "Saving Data" : "Save Data"}
                              </button>
                            </div>
                          </div>
                        </form>
                        </>
      )}
                      </div>
                    </div>
                    <CardLinks></CardLinks>
                  </div>
                </div>
             
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UpdateStudent;

const getColorModalClass = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9 || hours === 15) {
    return hours === 9 ? "modal-green" : "modal-red"; 
  }
  return "";
};

const getModalTitle = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is Open!";
  } else if (hours === 15) {
    return "Market is Closed!";
  }
  return "";
};

const getModalBody = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is currently open. Take necessary actions.";
  } else if (hours === 15) {
    return "Market is currently closed. Come back tomorrow.";
  }
  return "";
};
