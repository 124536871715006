import React, { useState, useRef } from "react";

import { useDropzone } from 'react-dropzone';
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate } from "react-router-dom";
import { Button } from 'react-bootstrap'; // Importing Button component from react-bootstrap
import { Toast as PrimeToast } from 'primereact/toast'; // Import PrimeReact Toast component
import axios from 'axios';
import StudentHeader from "./StudentHeader";
import SubHeaderS from "./SubHeaderS";
import config from "../../../component/config";
const FeatureRequestStudent = () => {
  const [backClicked, setBackClicked] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [fileError, setFileError] = useState("");
  const [errors, setErrors] = useState({});
  const toast = useRef(null);
  const navigate = useNavigate();

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpg", ".jpeg",".png"],
      "application/pdf": [".pdf"],
      "application/msword": [".doc",".docx"],
      "text/csv": [".csv",".txt"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    maxSize: 5 * 1024 * 1024,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        setFileError("File size exceeds 5 MB. Please upload a smaller file.");
        setSelectedFile(null);
        setSelectedFileName("");
      } else {
        setSelectedFile(acceptedFiles[0]);
        setSelectedFileName(acceptedFiles[0].name);
        setFileError("");
      }
    },
  });

  const validateProduct = (product) => {
    return product ? "" : "Please select a product.";
  };

  const validateFeature = (feature) => {
    if (!feature) {
      return "Please enter the new feature.";
    } else if (feature.length < 3) {
      return "Feature must be at least 3 characters long.";
    } else if (feature.length > 200) {
      return "Feature must be no more than 200 characters long.";
    }
    return "";
  };

  const validateExplanation = (explanation) => {
    if (!explanation) {
      return "Please explain how it would work.";
    } else if (explanation.length < 3) {
      return "Explanation must be at least 3 characters long.";
    } else if (explanation.length > 1000) {
      return "Explanation must be no more than 1000 characters long.";
    }
    return "";
  };

  const validateFields = () => {
    const newErrors = {};

    const product = document.getElementById("productSelect").value.trim();
    const feature = document.getElementById("featureInput").value.trim();
    const explanation = document
      .getElementById("explanationTextarea")
      .value.trim();

    newErrors.product = validateProduct(product);
    newErrors.feature = validateFeature(feature);
    newErrors.explanation = validateExplanation(explanation);

    setErrors(newErrors);
    return Object.keys(newErrors).every((key) => !newErrors[key]); // Returns true if there are no errors
  };

  const handleSubmit = async () => {
    if (!validateFields()) {
      return;
    }

    const authToken = localStorage.getItem("authToken");
    const userId = localStorage.getItem("userId");

    if (!authToken || !userId) {
      navigate("/login");
      return;
    }

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("user_id", userId);

      formData.append(
        "product",
        document.getElementById("productSelect").value
      );
      formData.append("feature", document.getElementById("featureInput").value);
      formData.append(
        "explanation",
        document.getElementById("explanationTextarea").value
      );
      if (selectedFile) {
        formData.append("example_file", selectedFile);
      }

      const response = await axios.post(
        `${config.apiDomain}/api/common/feature_request/create`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data.st === 1) {
        
        window.showToast("success ", "Feature request submitted successfully!");
        setTimeout(() => {
          navigate("/student/dashboard");
        }, 2000);
      } else {
       
        window.showToast("error ", "Failed to submit feature request.");

      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/login");
      } else {
        
        window.showToast("error ", "An error occurred while submitting the request.");

      }
      console.clear(); 
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (field, value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));
  };
  return (
    <div>
      <StudentHeader></StudentHeader>
      <SubHeaderS></SubHeaderS>
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/Student/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li className="breadcrumb-item active text-secondary" aria-current="page">
              Feature Request 
            </li>
          </ol>
        </nav>
        <div className="card p-6">
          <div className="row align-items-center">
            <div className="col-3 text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-6 text-center mb-5">
              <h5 className="mb-0">Feature Request</h5>
            </div>
            <div className="col-3 text-end"></div>
          </div>
          <div className="row">
          <div className="col-md-6">
  <div className="form-floating form-floating-outline">
    <select
      className={`form-select ${
        errors.product ? "is-invalid" : ""
      }`}
      id="productSelect"
      aria-label="Default select example"
      onChange={(e) => {
        handleInputChange("product", e.target.value);
      }}
      style={{ padding: '10px', fontSize: '16px' }}
    >
      <option value="" style={{ marginBottom: '10px' }}>Please Select</option>
      <option value="Product 1" style={{ marginBottom: '10px' }}>Product 1</option>
      <option value="Product 2" style={{ marginBottom: '10px' }}>Product 2</option>
      <option value="Product 3" style={{ marginBottom: '10px' }}>Product 3</option>
    </select>
    <label htmlFor="productSelect">
      <span className="text-danger">*</span> select the product for feature request
    </label>
    {errors.product && (
      <div className="invalid-feedback">{errors.product}</div>
    )}
  </div>
</div>


            <div className="col-md-12 mt-4">
              <div className="form-floating form-floating-outline ">
                <input
                  type="text"
                  className={`form-control ${
                    errors.feature ? "is-invalid" : ""
                  }`}
                  id="featureInput"
                  placeholder="Enter New Feature"
                  onChange={(e) => {
                    handleInputChange("feature", e.target.value);
                  }}
                />
                <label htmlFor="featureInput">
                  <span className="text-danger">*</span> What would you like to
                  add as a new feature?
                </label>
                {errors.feature && (
                  <div className="invalid-feedback">{errors.feature}</div>
                )}
              </div>
            </div>
            <div className="col-md-12 mt-4">
              <div className="form-floating form-floating-outline mb-6">
                <textarea
                  className={`form-control h-px-100 ${
                    errors.explanation ? "is-invalid" : ""
                  }`}
                  id="explanationTextarea"
                  placeholder="Type here..."
                  onChange={(e) => {
                    handleInputChange("explanation", e.target.value);
                  }}
                ></textarea>
                <label htmlFor="explanationTextarea">
                  <span className="text-danger">*</span> Please explain how it
                  would work
                </label>
                {errors.explanation && (
                  <div className="invalid-feedback">{errors.explanation}</div>
                )}
              </div>
            </div>
            <div className="col-12">
              <label htmlFor="dropzone-basic">
                Please Upload example, if there is any
              </label>
              <div className="dropzone-container bg-body">
                <div
                  className="dropzone needsclick mt-3"
                  id="dropzone-basic"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div className="dz-message needsclick">
                    <div className="mb-3">
                      <i className="ri-upload-2-fill text-muted ri-3x"></i>
                    </div>
                    <p className="text-muted font-weight-semibold">
                      Drop files here or click to upload.
                    </p>
                  </div>
                </div>
                {selectedFileName && (
                  <div className="mt-2">Selected File: {selectedFileName}</div>
                )}
                {fileError && (
                  <div className="text-danger mt-2">{fileError}</div>
                )}
              </div>
            </div>

            <div className="col-12 mt-4 text-end">
              <button
                type="submit"
                className="btn btn-success rounded-pill px-4 py-2"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <div className="spinner-border me-2" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>{" "}
                    Submitting
                  </>
                ) : (
                  <>
                    <i className="ri-checkbox-circle-line ri-lg me-1"></i>{" "}
                    Submit
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <PrimeToast ref={toast} />
    </div>
  );
};

export default FeatureRequestStudent;

