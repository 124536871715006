import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";

import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import axios from "axios";
import config from "../../../component/config";
import Header from "../component/Header";
import SubHeader from "../component/SubHeader";
import Footer from "../../../component/Footer";
import { Tooltip } from "primereact/tooltip";
import { Modal } from "react-bootstrap";
import CardLinks from "../../../component/CardLinks";
import { Toast } from "primereact/toast";
import SubscriptionBadge from "../../../component/SubscriptionBadge";
const StudentDatewiseReport = ({}) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { userId, month_name } = useParams();
  const [isRefreshing, setIsRefreshing] = useState(true);
  const authToken = localStorage.getItem("authToken");
  const toast = useRef(null);
  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      const authToken = localStorage.getItem("authToken");
  

      if (!authToken || !userId) {
        navigate("/login");
        return;
      }
      const response = await axios.post(
        `${config.apiDomain}/api/teacher/datewise_report`,
        {
          user_id: userId, 
          month_name: month_name,
          
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data && response.data.st === 1) {
        setData(response.data.completed_trades_per_date);
      } else {
        setError(new Error("No data found"));
      }
    } catch (error) {
      console.clear(); 
      if (error.response && error.response.status === 401) {
        
        navigate("/login");
      } else {
        setError(new Error(error.message || "Failed to fetch data"));
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [userId, month_name]);

  const handleBack = () => {
    navigate(-1);
  };

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 30)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success";
    } else if (hours === 15) {
      return "danger";
    }
    return "secondary";
  };

  const usernameBodyTemplate = (rowData) => {
    return <span>{toTitleCase(rowData.username)}</span>;
  };
  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const commissionBodyTemplate = (rowData) => {
    return ` ${rowData.total_commission} Rs.`;
  };

  const handleRefresh = async () => {
    setLoading(true);
    setError(null);
    setIsRefreshing(true);
  
    try {
      const response = await axios.post(
        `${config.apiDomain}/api/teacher/datewise_report`,
        {
          user_id: userId,
          month_name: month_name,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      const { st, msg, completed_trades_per_date } = response.data;
  
      if (st === 1) {
        setData(completed_trades_per_date);
       // window.showToast("success", msg || "Data fetched successfully");
      } else {
        // Handle other `st` values for warnings, errors, or info
        const errorMessage = msg || "No data available for the selected month";
        setError(new Error(errorMessage));
  
        switch (st) {
          case 2:
            window.showToast("danger", errorMessage || "Error fetching data.");
            break;
          case 3:
            window.showToast("warning", errorMessage || "Warning: No data found.");
            break;
          case 4:
            window.showToast("info", errorMessage || "Info: No data available for this month.");
            break;
          default:
            window.showToast("error", "Something went wrong. Contact Support.");
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access, redirecting to common login screen");
        navigate("/login");
        window.showToast("error", "Unauthorized access. Redirecting to login.");
      } else {
        const errorMessage = error.message || "Something went wrong. Contact Support.";
        setError(new Error(errorMessage));
        window.showToast("error", errorMessage);
      }
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };
  
  return (
    <>
      <Toast ref={toast} />
      <Header />
      <SubHeader />
      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
        <div className="d-flex justify-content-between align-items-center">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/teacher/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>

            <li className="breadcrumb-item">
              <Link to="/teacher/student_report" className="text-black">
                Student Report
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link
                to={`/teacher/student_report_list/${userId}/${month_name}`}
                className="text-black"
              >
                Student List
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              Datewise 
            </li>
          </ol>
          <SubscriptionBadge></SubscriptionBadge>
          </div>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center">
            <div className="col text-start mb-5 ">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col text-start mb-5">
              <h5 className="mb-0">  Datewise </h5>
            </div>
          </div>

          <div className="d-flex justify-content-start mb-3">
            <IconField iconPosition="left">
              <InputIcon className="ri ri-search-line"></InputIcon>
              <InputText
                type="search"
                placeholder="Search"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
                className="rounded"
              />
            </IconField>
            {loading ? (
              <div className="d-flex align-items-center">
                <i
                  className={`ri-refresh-line ri-lg ms-3 p-text-secondary ${
                    isRefreshing ? "rotate" : ""
                  }`}
                  role="status"
                ></i>
              </div>
            ) : (
              <div className="mt-3">
                <Tooltip target=".custom-target-icon" />
                <i
                  className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
                  role="button"
                  data-pr-tooltip="Reload"
                  onClick={handleRefresh}
                  data-pr-position="top"
                ></i>
              </div>
            )}
               <strong className="mt-3 ms-auto  fs-4">{month_name}</strong>
          </div>
          <DataTable
            className="custom-column-border"
            align="center"
            value={data}
            paginator
            rows={20}
            showGridlines
            loading={loading}
            globalFilter={globalFilter}
            emptyMessage="No records found"
          >
            <Column
              align="center"
              className="custom-column-border"
              field="sell_date"
              header="Exit Date"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="trades_count"
              header="Trades Count"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="profitable_trades"
              header="Profitable Trades"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="losing_trades"
              header="Losing Trades"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="commission"
              header="Commission"
              body={(rowData) => `${rowData.commission} Rs.`}
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              header="Actions"
              body={(rowData) => (
                <Link
                  to={`/teacher/student_report_details/${userId}/${rowData.sell_date}`}
                  state={{ month_name }}
              >
                  <Button
                    className="btn btn-info custom-btn-action1 custom-target-icon"
                    data-pr-tooltip="View Record"
                    data-pr-position="top"
                  >
                    <i className="ri-gallery-view-2"></i>
                  </Button>
                </Link>
              )}
            ></Column>
          </DataTable>
        </div>
        <CardLinks></CardLinks>
      </div>
      <Footer></Footer>
    </>
  );
};

export default StudentDatewiseReport;

const getColorModalClass = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9 || hours === 15) {
    return hours === 9 ? "modal-green" : "modal-red";
  }
  return "";
};

const getModalTitle = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is Open!";
  } else if (hours === 15) {
    return "Market is Closed!";
  }
  return "";
};

const getModalBody = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is currently open. Take necessary actions.";
  } else if (hours === 15) {
    return "Market is currently closed. Come back tomorrow.";
  }
  return "";
};
