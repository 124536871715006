import React, { useState, useEffect } from "react";
import Header from "../component/Header";
import SubHeader from "../component/SubHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import config from "../../../component/config";
import axios from "axios";
import Footer from "../../../component/Footer";
import CardLinks from "../../../component/CardLinks";
import SubscriptionBadge from "../../../component/SubscriptionBadge";
import Skeleton from 'react-loading-skeleton';

const SubscriptionPlan = () => {
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [backClicked, setBackClicked] = useState(false);
  const { subscriptionId, subscriptionType } = useParams();

  const fetchAllSubscriptionPlans = async (
    subscriptionId,
    subscriptionType
  ) => {
    setIsLoading(true);
    try {
      const authToken = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");

      if (!authToken || !userId) {
        navigate("/login");
        return;
      }
      const response = await axios.post(
        `${config.apiDomain}/api/teacher/view_all_subscription_plans`,
        {
          teacher_id: userId,
          subscription_id: subscriptionId,
          subscription_type: subscriptionType,
        },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      if (response.data.st === 1) {
        setSubscriptionPlans(response.data.all_subscription_plans_list || []);
      } else {
      }
    } catch (error) {
      console.clear(); 
    }
    finally {
      setIsLoading(false); // End loading
    }
  };
  useEffect(() => {
    fetchAllSubscriptionPlans(subscriptionId, subscriptionType);
  }, [subscriptionId, subscriptionType]);

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };
  const anyPlanSubscribed = subscriptionPlans.some((plan) => plan.subscribed);
  const handleSubscribe = (
    subscriptionId,
    subscriptionPlanId,
    subscriptionType
  ) => {
    navigate(
      `/teacher/payment_gateway/${subscriptionId}/${subscriptionPlanId}/${subscriptionType}`
    );
  };
  const gifs = [
    require("../../../assets/gif/1.gif"), // First GIF
    require("../../../assets/gif/2.gif"), // Second GIF
    require("../../../assets/gif/3.gif"),
    require("../../../assets/gif/4.gif"),
    require("../../../assets/gif/5.gif"),
  ];
  return (
    <div>
      <Header></Header>
      <SubHeader></SubHeader>
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
          <div className="d-flex justify-content-between align-items-center">
            <ol className="breadcrumb breadcrumb-style1 text-black">
              <li className="breadcrumb-item">
                <Link to="/teacher/dashboard" className="text-black">
                  <i className="ri-home-7-line ri-lg"></i>
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/teacher/subscription" className="text-black">
                  Subscriptions
                </Link>
              </li>
              <li
                className="breadcrumb-item active text-secondary"
                aria-current="page"
              >
                Subscription Plan
              </li>
            </ol>
            <SubscriptionBadge></SubscriptionBadge>
          </div>
        </nav>
        <div className="card p-6">
          <div className="row align-items-center">
            <div className="col-3 text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-6 text-center mb-5">
              <h5 className="mb-0">Subscription Plan</h5>
            </div>
            <div className="col-12 col-md-3 col-lg-3 text-end mb-5">
              <Link to="/teacher/payment_history">
                <button className="btn rounded-pill btn-outline-secondary btn-xs">
                  <span className="text-black">
                    <i className="ri-money-rupee-circle-line me-1 ri-lg"></i>{" "}
                    <span>Payment History</span>
                  </span>
                </button>
              </Link>
            </div>
          </div>

          <div className="col-xl-12 col-md-12 col-12 mb-md-0 mb-0">
            <div className="text-center col-12">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-12 mb-0 text-center">
                    <div className="bs-stepper wizard-modern wizard-modern-example  text-center">
                      <div className="bs-stepper-header">
                        <div
                          className="step"
                          data-target="#account-details-modern"
                        >
                          <button
                            type="button"
                            className="step-trigger d-flex flex-column align-items-center"
                          >
                            <span className="bs-stepper-circle bg-primary custom-stepper-circle"></span>
                            <span className="bs-stepper-label  text-center">
                              <span className="d-flex flex-column align-items-center">
                                <span className="bs-stepper-title">
                                  Subscription Plan
                                </span>
                              </span>
                            </span>
                          </button>
                        </div>

                        <div
                          className="progress mb-1 text-center rounded mt-3 mx-auto"
                          style={{ height: "9px", width: "55%" }}
                        >
                          <div
                            className="progress-bar rounded"
                            role="progressbar"
                            aria-valuenow="65"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <div
                          className="step"
                          data-target="#personal-info-modern"
                        >
                          <button
                            type="button"
                            className="step-trigger d-flex flex-column align-items-center"
                          >
                            <span className="bs-stepper-circle bg-white custom-stepper-circle d-flex justify-content-center align-items-center"></span>
                            <span className="bs-stepper-label mt-2 text-center">
                              <span className="d-flex flex-column gap-1">
                                <span className="bs-stepper-title">
                                  Payment
                                </span>
                              </span>
                            </span>
                          </button>
                        </div>
                      </div>
                      <p className="text-center mb-0 mx-auto w-100">
                        All plans include 40+ advanced tools and features to
                        boost your product. Choose the best plan to fit your
                        needs. All plans include 40+ advanced tools and features
                        to boost
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center ">
              {isLoading
                ? // Render skeleton placeholders while loading
                  [...Array(3)].map((_, index) => (
                    <div
                      className="col-lg-4 col-md-6 col-sm-12 mb-6 d-flex"
                      key={index}
                    >
                      <div className="card w-100 border-light d-flex flex-column h-100">
                        <div className="card-body d-flex flex-column position-relative pt-4">
                          <h5 className="card-title text-center text-capitalize mb-1 mt-3">
                            <Skeleton width={100} height={24} />
                          </h5>

                          <div className="my-5 pt-3 text-center">
                            <Skeleton circle={true} height={100} width={100} />
                          </div>

                          <div className="text-center">
                            <div className="d-flex justify-content-center">
                              <Skeleton width={50} height={40} />
                              <Skeleton
                                width={30}
                                height={20}
                                className="ms-2"
                              />
                            </div>
                          </div>

                          <p className="text-center text-capitalize my-3 mt-6 mb-6 px-3 lh-base flex-grow-1">
                            <Skeleton count={2} />
                          </p>

                          <ul className="text-start p-1 ps-4 list-unstyled mt-auto">
                            {[...Array(5)].map((_, i) => (
                              <li className="my-3" key={i}>
                                <Skeleton width={`80%`} />
                              </li>
                            ))}
                          </ul>

                          <div className="d-grid w-100 mt-auto">
                            <Skeleton height={40} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : subscriptionPlans.map((plan, index) => {
                    const isPopular = index === subscriptionPlans.length - 2; // Popular card indicator
                    const isFree = plan.pricing === "0"; // Free plan indicator

                    return (
                      <div
                        className="col-lg-4 col-md-6 col-sm-12 mb-6 d-flex"
                        key={plan.subscription_plan_id}
                      >
                        <div
                          className={`card w-100 ${
                            isPopular ? "animated-border" : "border-light"
                          } d-flex flex-column h-100`}
                        >
                          <div className="card-body d-flex flex-column position-relative pt-4">
                            {isPopular && (
                              <div className="position-absolute end-0 me-6 top-0 mt-6">
                                <span className="badge bg-label-primary rounded-pill">
                                  Popular
                                </span>
                              </div>
                            )}

                            {/* Badge for Free Plan */}
                            {isFree ? (
                              <h5 className="card-title text-center text-capitalize mb-1 mt-3">
                                <span className="badge bg-label-success rounded-pill">
                                  {plan.plan_name}
                                </span>
                              </h5>
                            ) : (
                              <h4 className="card-title text-center text-capitalize mb-1 mt-3">
                                {plan.plan_name
                                  .split(" ")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1).toLowerCase()
                                  )
                                  .join(" ")}
                              </h4>
                            )}

                            <div className="my-5 pt-3 text-center">
                              <img
                                src={gifs[index % gifs.length]}
                                alt="Plan Image"
                                height="100"
                              />
                            </div>

                            {/* Pricing Section */}
                            <div className="text-center">
                              <div className="d-flex justify-content-center">
                                <sup className="h6 pricing-currency mt-2 mb-0 me-1 text-body">
                                  ₹
                                </sup>
                                <h1 className="price-toggle price-yearly text-primary mb-0">
                                  {new Intl.NumberFormat("en-IN").format(
                                    plan.pricing
                                  )}
                                </h1>
                                <sub className="h6 pricing-duration mt-auto mb-1 text-body ms-2">
                                  /{plan.service_period} Days
                                </sub>
                              </div>
                            </div>

                            <p className="text-center text-capitalize my-3 mt-6 mb-6 px-3 lh-base flex-grow-1">
                              {plan.plan_description}
                            </p>

                            <ul className="text-start p-1 ps-4 list-unstyled mt-auto">
                              <li className="my-3">
                                <i className="ri-user-3-fill text-primary me-3 fs-5 "></i>
                                Account handled by{" "}
                                <span className="fw-bold">1</span> Master
                                Account.
                              </li>
                              <li className="my-3">
                                <i className="ri-group-line text-primary me-3 fs-5"></i>
                                Copy trades on{" "}
                                <span className="fw-bold">
                                  {plan.max_user_limit}
                                </span>{" "}
                                student accounts.
                              </li>
                              <li className="my-3">
                                <i className="ri-settings-4-line text-primary me-3 fs-5"></i>
                                <span className="fw-bold">
                                  {plan.service_period} Days
                                </span>{" "}
                                service.
                              </li>
                              <li className="my-3">
                                <i className="ri-folder-chart-line text-primary me-3 fs-5"></i>
                                Reporting: Daily, Weekly, Monthly
                              </li>
                              <li className="my-3">
                                <i className="ri-calendar-line text-primary me-3 fs-5"></i>{" "}
                                Activity Plan
                              </li>
                            </ul>

                            {plan.subscribed ? (
                              <button
                                type="button"
                                className="btn btn-outline-info d-grid w-100 mt-auto d-flex align-items-center justify-content-center"
                                disabled
                              >
                                <i className="ri ri-checkbox-circle-line ri-lg me-2"></i>
                                <span>Subscribed</span>
                              </button>
                            ) : (!anyPlanSubscribed && plan.pricing === "0") ||
                              plan.pricing !== "0" ? (
                              <button
                                type="button"
                                className="btn btn-primary d-grid w-100 mt-auto"
                                onClick={() =>
                                  handleSubscribe(
                                    plan.subscription_id,
                                    plan.subscription_plan_id,
                                    plan.subscription_type
                                  )
                                }
                              >
                                Subscribe Now
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
        <CardLinks></CardLinks>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default SubscriptionPlan;
