import React from 'react'
import calender from "../../../assets/img/products/calender-spreads.jpg"
import strategy from "../../../assets/img/products/order-strategy.jpg"
import call from "../../../assets/img/products/call-executer.jpg"
import multi from "../../../assets/img/products/multi-strategy.jpg"
import multi2 from "../../../assets/img/products/multi-strategy-2.jpg"
import trade from "../../../assets/img/products/trend-trader.jpg"
import candle from "../../../assets/img/products/candle-trading-strategy.jpg"
const AlgoTradingStrategy = () => {
  return (

    <section id="landingFeatures" className="landing-features  pt-10 mb-5"> 
    <div className='container-xxl '  >
      <h2 className="fw-bold col-12">Pre-Built  Strategies</h2>
      <p className="text-muted mb-5">
        Take your trading prowess to the next level effortlessly with our Pre-Built Trading Strategies—meticulously crafted and ready to deploy, offering a shortcut to success in the dynamic world of financial markets.
      </p>

      {/* First Strategy: Calendar Spreads */}
      <div className="row  " style={{ textAlign: "justify", lineHeight: "1.8" }}>
        <div className="col-lg-7 order-lg-2">
          <h4 className="fw-bold mb-0">Calendar Spreads</h4>
          <small className="text-muted">4 Min read | 23 Sep 2023</small>
          <p className="mt-3">
            Explore calendar spreads in Algo trading! This strategy involves holding a long and short position with the same strike price and expiration date but across different months. The forward month helps hedge the initial option's price risk, providing flexibility for adjusting or exiting spreads before expiration to adapt to market shifts.
          </p>
          <a href="#" className="btn btn-primary">Read more</a>
        </div>
        
        <div className="col-lg-5 col-md-5 col-12 mt-2 mt-md-0 mt-lg-0 text-center text-md-start">
  <img src={calender} alt="Calendar Spreads" className="img-fluid h-75 rounded" />
</div>

      </div>

      {/* Second Strategy: Order Slicer Strategy */}
      <div className="row ">
      
        <div className="col-lg-7 ">
          <h4 className="fw-bold mb-0">Order Slicer Strategy</h4>
          <small className="text-muted">4 Min read | 23 Sep 2023</small>
          <p className="mt-3">
            Maximize market impact with the Order Slicer strategy, involving breaking a large order into smaller, manageable parts executed gradually to reduce the risk of adverse price movement. This strategy reduces market impact, improves execution efficiency, and helps traders maintain control over their trades.
          </p>
          <a href="#" className="btn btn-primary">Read more</a>
        </div>
        
        <div className="col-md-5 col-12 mt-2 mt-md-0 mt-lg-0 text-center text-md-start d-flex justify-content-end">
          <img src={strategy} alt="Order Slicer" className="img-fluid h-75 rounded" />
        </div>
        
      </div>

      <div className="row  " >
        <div className="col-lg-7 order-lg-2">
          <h4 className="fw-bold mb-0">Call Executor Strategy</h4>
          <small className="text-muted">4 Min read  |  25 Feb 2024</small>
          <p className="mt-3">
          Harness the power of automation with Call Executor—an Excel-based algorithmic strategy offering real-time data utilisation. Accessible and affordable, it empowers traders with rule-based, automated executions. Capitalise on Excel's data connection capabilities for up-to-date insights on stock prices, volumes, and economic indicators, informing strategic decisions. However, be mindful of Excel's limitations, particularly in high-frequency or extensive market data scenarios.          </p>
          <a href="#" className="btn btn-primary">Read more</a>
        </div>
        
        <div className="col-md-5 col-12 mt-2 mt-md-0 mt-lg-0 text-center text-md-start ">
          <img src={call} alt="Calendar Spreads" className="img-fluid h-75 rounded" />
        </div>
      </div>

      <div className="row ">
      
      <div className="col-lg-7 ">
        <h4 className="fw-bold mb-0">Scalping Trading Strategy
        </h4>
        <small className="text-muted">4 Min read | 23 Sep 2023</small>
        <p className="mt-3">
        Scalping, a day trading strategy, involves quick buying and selling of a single stock to make small profits across numerous deals in a short period. Scalpers prioritise technical trading over fundamental analysis, focusing on historical price patterns and current trends. Using ultra-short timeframes, as brief as five seconds, scalpers make 10 to 100 trades daily. Scalping stands out for its emphasis on rapid, technical trading techniques, distinct from other methods like position trading.

</p>
        <a href="#" className="btn btn-primary">Read more</a>
      </div>
      
      <div className="col-md-5 col-12 mt-2 mt-md-0 mt-lg-0 text-center text-md-start d-flex justify-content-end ">
        <img src={multi} alt="Order Slicer" className="img-fluid h-75 rounded" />
      </div>
      
    </div>

    <div className="row  " >
        <div className="col-lg-7 order-lg-2">
          <h4 className="fw-bold mb-0">Multi-Leg Strategy
          </h4>
          <small className="text-muted">4 Min read  |  25 Feb 2024</small>
          <p className="mt-3">
          Optimise your options trading with multi-leg orders—simultaneous trades involving different strike costs, expiration dates, or asset price preferences. These orders, like spreads and butterflies, aim to trade amid volatility. Pros include potential loss limitation through strategic planning.


                            </p>
          <a href="#" className="btn btn-primary">Read more</a>
        </div>
        
        <div className="col-md-5 col-12 mt-2 mt-md-0 mt-lg-0 text-center text-md-start">
          <img src={multi2} alt="Calendar Spreads" className="img-fluid h-75 rounded" />
        </div>
      </div>


      <div className="row ">
      
      <div className="col-lg-7 ">
        <h4 className="fw-bold mb-0">Trend Trader

        </h4>
        <small className="text-muted">4 Min read | 23 Sep 2023</small>
        <p className="mt-3">
        Embrace trend trading for strategic gains! This popular strategy involves identifying and capitalising on sustained market trends, be it bullish or bearish. Traders align with the trend, buying long positions in an upward trend and selling short positions in a downward trend. Trend traders adopt a long-term perspective, utilizing technical indicators like moving averages for informed decisions.


</p>
        <a href="#" className="btn btn-primary">Read more</a>
      </div>
      
      <div className="col-md-5 col-12 mt-2 mt-md-0 mt-lg-0 text-center text-md-start d-flex justify-content-end">
        <img src={trade} alt="Order Slicer" className="img-fluid h-75 rounded" />
      </div>
      
    </div>

    <div className="row  " >
        <div className="col-lg-7 order-lg-2">
          <h4 className="fw-bold mb-0">Candle trading strategy

          </h4>
          <small className="text-muted">4 Min read  |  25 Feb 2024</small>
          <p className="mt-3">
          Unlock the power of candlestick patterns in quantitative trading! These simple formations offer easy interpretation, making them valuable for traders. With a basic understanding of candlestick formation, quantitative strategies leverage open, high, low, and close price fluctuations in various tradable instruments. The master candlestick strategy, a breakout technique, identifies new price ranges, anticipating significant fluctuations post-breakout.



                            </p>
          <a href="#" className="btn btn-primary ">Read more</a>
        </div>
        
        <div className="col-md-5 col-12 mt-2 mt-md-0 mt-lg-0 text-center text-md-start  rounded">
          <img src={candle} alt="Calendar Spreads" className="img-fluid h-75" />
        </div>
      </div>

    </div>
    </section>
    
   
  )
}

export default AlgoTradingStrategy
